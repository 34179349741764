/** @format */

import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './assetManagement.module.css';
import theme from '../theme';
import { Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getAssetWithCount } from '../../services/private/assetManagement/assetServices';
import { debounce } from '../../services/helpers/helper';


const Tenants = () => {


  const navigate = useNavigate();
  const [assetData, setAssetData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  async function serverRequet() {
    const entities = await getAssetWithCount();
    setAssetData(entities.data);
    setOriginalData(entities.data)
  }
  useEffect(() => {
    serverRequet();
  }, []);
  const handleInputChange = (e) => {
    const query = e.target.value
    setAssetData(originalData)
    handleSearch(query)
  }

  const handleSearch = debounce(async (query) => {
    if (!query.trim()) {
      setAssetData(originalData);
      return
    }
    const searchTerm = query.toLowerCase()
    const matchedAssets = assetData.filter((asset) =>
      asset.assetData.name.toLowerCase().includes(searchTerm) || asset.assetData.property_type.toLowerCase().includes(searchTerm) || asset.assetData.country.toLowerCase().includes(searchTerm) || asset.assetData.state.toLowerCase().includes(searchTerm)
    )
    setAssetData(matchedAssets)
  }, 500)
  return (
    <Stack className={styles.container} gap={2} p={3}>
      <Typography variant="h5" className={styles.pageTitle}>
        Tenants
      </Typography>
      <Typography variant="body" className={styles.description}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages,
      </Typography>
      <Box>
        <TextField
          variant="outlined"
          label="Search"
          placeholder="Asset Name, Property Type, Country, State"
          size="small"
          onChange={handleInputChange}
        />
      </Box>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow
              className={styles.tableHeadRow}
              sx={{ bgcolor: theme.palette.esgblueblack.main }}
            >
              <TableCell className={styles.tableHeadCell}>Asset Name</TableCell>
              <TableCell className={styles.tableHeadCell}>
                Property Type
              </TableCell>
              <TableCell className={styles.tableHeadCell}>Country</TableCell>
              <TableCell className={styles.tableHeadCell}>City/State</TableCell>
              <TableCell className={styles.tableHeadCell}>
                No. of Tenants
              </TableCell>
              <TableCell className={styles.tableHeadCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assetData?.map((item) => (
              <TableRow
                className={styles.tableBodyRow}
                key={item?.assetData?.id}
              >
                <TableCell className={styles.tableBodyCell}>
                  {item?.assetData?.name}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {item?.assetData?.property_type}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {item?.assetData?.country}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {item?.assetData?.city} / {item?.assetData?.state}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  {item?.tenantCount}
                </TableCell>
                <TableCell className={styles.tableBodyCell}>
                  <IconButton
                    size="small"
                    onClick={() =>
                      navigate(`view-assets/${item?.assetData?.id}`, {
                        state: { name: item.assetData.name },
                      })
                    }
                    sx={{ color: theme.palette.esgblueblack.main }}
                  >
                    <Visibility />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default Tenants;
