/** @format */

import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './accordionQuestionnaire.module.css';
import { getDataVault } from '../../../services/private/dataVault/dataVaultService';
import { errorToast } from '../../../utils/alerts';
import { Add, Remove } from '@mui/icons-material';

const AccordionQuestionnaire = ({ tab, type }) => {
  // eslint-disable-next-line
  const [selectedOption, setSelectedOption] = useState();
  const [data, setData] = useState([]);
  const [expandedAccordions, setExpandedAccordions] = useState(
    data.map(() => false) // Initialize with all accordions collapsed
  );

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);
  const handleOptionChange = (event, ind) => {
    const { value } = event.target;
    const updatedData = [...data];

    // Update the answer key for the specific item
    updatedData[ind].answer = value;
    if (value === 'yes') {
      expandAccordion(ind);
    } else {
      collapseAccordion(ind);
    }
    // Update the state with the modified data
    setData(updatedData);
  };

  // Expand accordion
  const expandAccordion = (index) => {
    const updatedExpanded = [...expandedAccordions];
    updatedExpanded[index] = true; // Set the specific accordion to expanded
    setExpandedAccordions(updatedExpanded); // Update state
  };

  // Collapse accordion
  const collapseAccordion = (index) => {
    const updatedExpanded = [...expandedAccordions];
    updatedExpanded[index] = false; // Set the specific accordion to collapsed
    setExpandedAccordions(updatedExpanded); // Update state
  };

  const serverRequest = async () => {
    try {
      setIsLoading(true);
      const rep = await getDataVault(tab, type);
      setData(rep?.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    } catch (error) {
      errorToast(error.message);
      setIsLoading(false);
    }
  };

  // Toggle the accordion expansion for a specific index
  const toggleAccordion = (index) => {
    const updatedExpanded = [...expandedAccordions];
    updatedExpanded[index] = !updatedExpanded[index]; // Toggle the current index
    setExpandedAccordions(updatedExpanded); // Update state
  };

  useEffect(() => {
    serverRequest();
    // eslint-disable-next-line
  }, []);
  return (
    <Stack className={styles.accordionContainer}>
      <FormControl>
        <Stack gap={2}>
          {data.map((item, ind) => (
            <Stack
              sx={{
                borderRadius: '8px',
                background: 'rgba(246, 247, 251, 1)',
                boxShadow: '0px 4px 24px 0px rgba(15, 51, 61, 0.10)',
              }}
              p={3}
              className={styles.accordionCard}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                border={'none'}
              >
                <Typography variant="p" className="question-label">
                  {item.question}
                </Typography>
                <IconButton onClick={() => toggleAccordion(ind)}>
                  {expandedAccordions[ind] ? <Remove /> : <Add />}
                </IconButton>
              </Stack>
              <RadioGroup
                aria-label="YesNoOptions"
                name="yesNoOptions"
                value={selectedOption}
                onChange={(e) => handleOptionChange(e, ind)}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
              {expandedAccordions[ind] &&
                item.sub.map((qs) => (
                  <Stack>
                    <Typography variant="p" className={styles.questionLabel}>
                      {qs.question}
                    </Typography>
                    <Stack direction="row" gap={2} mb={3}>
                      <TextField
                        variant="standard"
                        label={qs.question_title1}
                        sx={{ width: '100%' }}
                      />
                      <TextField
                        variant="standard"
                        label={qs.question_title2}
                        sx={{ width: '100%' }}
                      />
                    </Stack>
                  </Stack>
                ))}
            </Stack>
          ))}
        </Stack>
      </FormControl>

      <Stack direction="row" mt={2} gap={2}>
        <Button variant="contained" sx={{ color: '#fff' }}>
          Save
        </Button>
        <Button>Clear</Button>
      </Stack>
    </Stack>
  );
};

export default AccordionQuestionnaire;

/* <Typography
                    variant="p"
                    className={styles.questionLabel}
                    mt={2}
                    mb={2}
                  >
                    Employee(s) for whom ESG is among their responsibilities.
                    Provide the details for the most senior of these employees
                  </Typography>
                  <Stack direction="row" gap={2}>
                    <TextField
                      variant="standard"
                      label="Name"
                      sx={{ width: '100%' }}
                    />
                    <TextField
                      variant="standard"
                      label="Job Title"
                      sx={{ width: '100%' }}
                    />
                  </Stack>
                  <Typography
                    variant="p"
                    className={styles.questionLabel}
                    mt={2}
                    mb={2}
                  >
                    External consultants/manager. Provide the details of the
                    main contact
                  </Typography>
                  <Stack direction="row" gap={2}>
                    <TextField
                      variant="standard"
                      label="Name"
                      sx={{ width: '100%' }}
                    />
                    <TextField
                      variant="standard"
                      label="Job Title"
                      sx={{ width: '100%' }}
                    />
                  </Stack>
                  <Typography
                    variant="p"
                    className={styles.questionLabel}
                    mt={2}
                    mb={2}
                  >
                    External consultants/manager. Provide the details of the
                    main contact
                  </Typography>
                  <Stack direction="row" gap={2}>
                    <TextField
                      variant="standard"
                      label="Name"
                      sx={{ width: '100%' }}
                    />
                    <TextField
                      variant="standard"
                      label="Job Title"
                      sx={{ width: '100%' }}
                    />
                  </Stack>
      <Stack
              sx={{
                borderRadius: '8px',
                background: '#fff',
                boxShadow: '0px 4px 24px 0px rgba(15, 51, 61, 0.10)',
              }}
              p={3}
            >
              <Typography variant="p" className={styles.questionLabel}>
                Does the entity have one or more persons responsible for
                implementing climate-related objectives?
              </Typography>
              <RadioGroup
                aria-label="YesNoOptions"
                name="yesNoOptions"
                value={selected2Option}
                onChange={handleOption2Change}
                sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>

              {selected2Option === 'yes' && (
                <Stack>
                  <Stack direction="row" gap={2} sx={{ width: '100%' }}>
                    <TextField
                      variant="standard"
                      label="Name"
                      sx={{ width: '100%' }}
                    />
                    <TextField
                      variant="standard"
                      label="Job Title"
                      sx={{ width: '100%' }}
                    />
                  </Stack>
                  <Typography
                    variant="p"
                    className={styles.questionLabel}
                    mt={2}
                    mb={2}
                  >
                    Employee(s) for whom ESG is among their responsibilities.
                    Provide the details for the most senior of these employees
                  </Typography>
                  <Stack direction="row" gap={2} sx={{ width: '100%' }}>
                    <TextField
                      variant="standard"
                      label="Name"
                      sx={{ width: '100%' }}
                    />
                    <TextField
                      variant="standard"
                      label="Job Title"
                      sx={{ width: '100%' }}
                    />
                  </Stack>
                  <Typography
                    variant="p"
                    className={styles.questionLabel}
                    mt={2}
                    mb={2}
                  >
                    External consultants/manager. Provide the details of the
                    main contact
                  </Typography>
                  <Stack direction="row" gap={2} sx={{ width: '100%' }}>
                    <TextField
                      variant="standard"
                      label="Name"
                      sx={{ width: '100%' }}
                    />
                    <TextField
                      variant="standard"
                      label="Job Title"
                      sx={{ width: '100%' }}
                    />
                  </Stack>
                  <Typography
                    variant="p"
                    className={styles.questionLabel}
                    mt={2}
                    mb={2}
                  >
                    Investment partners (co-investors/JV partners). Provide the
                    details of the main contact
                  </Typography>
                  <Stack direction="row" gap={2} sx={{ width: '100%' }}>
                    <TextField
                      variant="standard"
                      label="Name"
                      sx={{ width: '100%' }}
                    />
                    <TextField
                      variant="standard"
                      label="Job Title"
                      sx={{ width: '100%' }}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Stack
              sx={{
                borderRadius: '8px',
                background: '#fff',
                boxShadow: '0px 4px 24px 0px rgba(15, 51, 61, 0.10)',
              }}
              p={3}
            >
              <Typography variant="p" className={styles.questionLabel}>
                Is there an ESG taskforce or committee?
              </Typography>
              <RadioGroup
                aria-label="YesNoOptions"
                name="yesNoOptions"
                value={selected3Option}
                onChange={handleOption3Change}
                sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>

              {selected3Option === 'yes' && (
                <>
                  <Stack direction="row" gap="10px" alignItems="center">
                    <Checkbox />
                    <Typography variant="p" className={styles.questionLabel}>
                      Board of Directors
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap="10px" alignItems="center">
                    <Checkbox />
                    <Typography variant="p" className={styles.questionLabel}>
                      C-suite level staff/Senior management
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap="10px" alignItems="center">
                    <Checkbox />
                    <Typography variant="p" className={styles.questionLabel}>
                      Investment Committee
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap="10px" alignItems="center">
                    <Checkbox />
                    <Typography variant="p" className={styles.questionLabel}>
                      Fund/portfolio managers
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap="10px" alignItems="center">
                    <Checkbox />
                    <Typography variant="p" className={styles.questionLabel}>
                      Asset managers
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap="10px" alignItems="center">
                    <Checkbox />
                    <Typography variant="p" className={styles.questionLabel}>
                      ESG portfolio manager
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap="10px" alignItems="center">
                    <Checkbox />
                    <Typography variant="p" className={styles.questionLabel}>
                      Investment analysts
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap="10px" alignItems="center">
                    <Checkbox />
                    <Typography variant="p" className={styles.questionLabel}>
                      Dedicated staff on ESG issues
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap="10px" alignItems="center">
                    <Checkbox />
                    <Typography variant="p" className={styles.questionLabel}>
                      External managers or service providers
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap="10px" alignItems="center">
                    <Checkbox />
                    <Typography variant="p" className={styles.questionLabel}>
                      Investor relations
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap="10px" alignItems="center">
                    <Checkbox />
                    <Typography variant="p" className={styles.questionLabel}>
                      Other:
                    </Typography>
                    <TextField variant="standard" />
                  </Stack>
                </>
              )}
            </Stack> */
