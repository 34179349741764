/**  @format */

import React, { useEffect, useState } from 'react';
import DataVault from '../DataVault';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import styles from './sdgQuestionnaire.module.css';
import { Upload, Add, Remove } from '@mui/icons-material';
import { useLocation, useParams } from 'react-router-dom';
import {
  getSDGQustions,
  postQuestionResponse,
} from '../../../services/private/dataVault/dataVaultService';
import { errorToast, successToast } from '../../../utils/alerts';

const SdgQuestionnaire = () => {
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const sdgData = location.state?.sdgData;

  useEffect(() => {
    fetchQuestionsFromAPI();
    // eslint-disable-next-line
  }, []);

  const fetchQuestionsFromAPI = async () => {
    try {
      setIsLoading(true);
      const { data } = await getSDGQustions(id);
      const initializedQuestions = data.map((q) => ({
        ...q,
        answer: {
          is_relevant: q.answer?.is_relevant || null,
          comments: q.answer?.comments || '',
          attachment: q.answer?.attachment || null,
        },
      }));
      setQuestions(initializedQuestions);
      setIsLoading(false);
    } catch (error) {
      errorToast(error.message);
      setIsLoading(false);
    }
  };

  // Handle all input changes dynamically, including file uploads
  const handleInputChange = (value, questionId, field) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId
          ? {
              ...q,
              answer: {
                ...q.answer,
                [field]:
                  field === 'is_relevant' ? (value === 'yes' ? 1 : 0) : value,
              },
            }
          : q
      )
    );

    // Show a success toast when a file is uploaded
    if (field === 'attachment') {
      successToast(`${value.name} Uploaded Successfully!`);
    }
  };

  const handleResponse = async (question) => {
    const { id, answer } = question;
    const formData = new FormData();
    formData.append('question_id', id);
    formData.append('is_relevant', answer.is_relevant);

    if (answer.is_relevant === 1) {
      formData.append('comments', answer.comments || '');
      if (answer.attachment) {
        formData.append('file', answer.attachment); // Assuming attachment is a file object
      }
    }

    const res = await postQuestionResponse(formData);
    if (res.message === 'Created successfully')
      successToast('Submitted Successfully!');
    handleChange(id);
  };

  const handleClear = (questionId) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === questionId
          ? {
              ...q,
              answer: { is_relevant: null, comments: '', attachment: null },
            }
          : q
      )
    );
  };

  // Handle accordion expand/collapse
  const handleChange = (questionId) => {
    setExpanded(expanded === questionId ? false : questionId);
  };

  return (
    <DataVault>
      <Stack className={styles.sdgQuestionnaireContainer} p={3} gap={2}>
        <Typography variant="h5" className={styles.title}>
          {`${sdgData.title} - ${sdgData.description}`}
        </Typography>
        <Typography variant="p" className={styles.brief}>
          {sdgData.detail}
        </Typography>
        <Typography variant="p" className={styles.questionCount}>
          {`Questions Answered: ${sdgData.answered_questions}/${sdgData.total_questions}`}
        </Typography>

        <Stack gap={2}>
          {questions.map((question) => (
            <Stack
              sx={{
                borderRadius: '8px',
                background: '#fff',
                boxShadow: '0px 4px 24px 0px rgba(15, 51, 61, 0.10)',
              }}
              key={question?.id}
            >
              <Accordion
                expanded={expanded === question.id}
                onChange={() => handleChange(question.id)}
                sx={{ padding: '12px 24px' }}
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={expanded === question.id ? <Remove /> : <Add />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    variant="body1"
                    className={styles.questionLabel}
                    sx={{
                      width: '1170px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {question.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" className={styles.isRelevant}>
                    Is this SDG relevant to you?
                  </Typography>
                  <RadioGroup
                    aria-label="YesNoOptions"
                    value={question.answer.is_relevant ? 'yes' : 'no'}
                    onChange={(e) =>
                      handleInputChange(
                        e.target.value,
                        question.id,
                        'is_relevant'
                      )
                    }
                    sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}
                  >
                    {['yes', 'no'].map((option) => (
                      <FormControlLabel
                        key={option}
                        value={option}
                        control={<Radio />}
                        label={
                          option === 'yes'
                            ? 'Yes, I support this goal.'
                            : 'No, this is not relevant to me.'
                        }
                        checked={
                          question.answer.is_relevant ===
                          (option === 'yes' ? 1 : 0)
                        }
                      />
                    ))}
                  </RadioGroup>

                  {question.answer.is_relevant === 1 && (
                    <Stack gap={2} my={2}>
                      <Stack direction="row" alignItems="flex-end" gap={2}>
                        <TextField
                          label="Comments"
                          sx={{ width: '90%' }}
                          multiline
                          minRows={2}
                          maxRows={4}
                          value={question.answer.comments || ''}
                          onChange={(e) =>
                            handleInputChange(
                              e.target.value,
                              question.id,
                              'comments'
                            )
                          }
                        />
                        <Stack
                          justifyContent={'space-between'}
                          alignItems={'flex-end'}
                          sx={{ minWidth: '10%' }}
                        >
                          {question.answer.attachment && (
                            <Typography variant="body1">
                              {question.answer.attachment.name}
                            </Typography>
                          )}
                          <Button
                            component="label"
                            startIcon={<Upload />}
                            sx={{ minWidth: '100%' }}
                          >
                            <Typography>Upload File</Typography>
                            <input
                              type="file"
                              hidden
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.files[0],
                                  question.id,
                                  'attachment'
                                )
                              }
                            />
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                  )}

                  <Stack direction="row" gap={2}>
                    <Button
                      variant="contained"
                      sx={{ color: '#fff' }}
                      onClick={() => handleResponse(question)}
                    >
                      Save
                    </Button>
                    {question.answer.is_relevant === 1 && (
                      <Button
                        variant="outlined"
                        onClick={() => handleClear(question.id)}
                      >
                        Clear
                      </Button>
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </DataVault>
  );
};

export default SdgQuestionnaire;
