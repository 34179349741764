import React, { useCallback, useEffect, useState } from 'react'
import ScrollableTable from './ScrollableTable'
import { buildingCertification } from '../../constant/assetManagement'
import { addBuildingCertificates, deleteBuildingCertificates, getBuildingCertificates, updateBuildingCertificates } from '../../services/private/assetManagement/assetServices'
import { errorToast } from '../../utils/alerts'
const BuildingCertifcationsDataTable = ({addField, setAddField, searchQuery}) => {
    const limit = 10
    // eslint-disable-next-line
    const [currentPage, setCurrentPage ] =useState(1)
    const [data ,setData ] = useState([])
    const [originalData, setOriginalData] = useState([])
    const [trigger, setTrigger] = useState(0)
    const serverRequest = useCallback(async() =>{
        try {
            const entites = await getBuildingCertificates(limit, currentPage)
            console.log("entities", entites.entities);
            setData(entites.data)
            setOriginalData(entites.data)
        } catch (error) {
            errorToast(error.message)         
        }
    },[currentPage])
    useEffect(()=>{
        serverRequest()
    },[serverRequest, trigger])

    const handleUpdateAsset = async(payload , id)=>{
        const res = await updateBuildingCertificates(payload, id)
        if(res.type=== 'RXSUCCESS'){
            setTrigger((prev)=> prev + 1)
        }
        return res
    }
    const handleDeleteAsset = async (id) => {
        const res = await deleteBuildingCertificates(id)
        if(res.type === 'RXSUCCESS'){
            setTrigger((prev)=> prev+1)
        }
        return res
    }
    const handleAddAsset = async ( payload ) => {
        const res = await addBuildingCertificates(payload)
        if(res.type === 'RXSUCCESS'){
            setTrigger((prev)=> prev+1)
        }
        return res
    }
    useEffect(()=>{
        if(searchQuery){
            const searchTerm = searchQuery.toLowerCase()
            const filteredData = originalData.filter((item)=> item.asset_name?.toLowerCase().includes(searchTerm))
            setData(filteredData)
        }else{
            setData(originalData)
        }
    },[searchQuery, originalData])
    
  return (
        

        <ScrollableTable
            tableContent={buildingCertification}
            headCellPadding={45} 
            colLength={13}
            actionCellPadding={45}
            cellBorder="1px solid #e8ebf2;"
            tab1={true}
            assetData={data}
            addField={addField}
            setAddField={setAddField}
            postRequest={handleAddAsset}
            putRequest={handleUpdateAsset}
            deleteRequest={ handleDeleteAsset}
          /> 
  
  )
}

export default BuildingCertifcationsDataTable