import React, { useState, useEffect } from 'react'
import { energy } from '../../constant/assetManagement'
import useGroupedAssets from '../../hooks/useGroupedAssets'
import ScrollableTable from './ScrollableTable'
import { addEnergyAsset, deleteEnergyAsset, getEnergyAsset, updateEnergyAsset } from '../../services/private/assetManagement/assetServices'
const EnergyDataTable = ({addField, setAddField, searchQuery}) => {
  const [trigger, setTrigger] = useState(0)
  const data = useGroupedAssets(getEnergyAsset, trigger)
  const [assetData, setAssetData ]= useState(data)
  const handleAddAsset = async(formData)=>{
    const res = await addEnergyAsset(formData)
    if(res.type==="RXSUCCESS"){
      setTrigger((prev)=> prev+1)
    }
    return res
  }
  const handleDeleteAsset = async (id)=>{
    const res = await deleteEnergyAsset(id)
    if(res.type==="RXSUCCESS"){
      setTrigger((prev) => prev + 1); // Toggle refreshTrigger to re-fetch data
    }
    return res
  }
  const handleUpdateAsset = async (payload, id) => {
    const res = await updateEnergyAsset(payload, id)
    if (res.type === "RXSUCCESS") {
      setTrigger((prev) => prev + 1)
    }
    return res
  }
  useEffect(()=>{
    if(searchQuery){
      const searchTerm = searchQuery.toLowerCase()
      const filteredData = data.filter((item)=>
        item.asset_name.toLowerCase().includes(searchTerm)
      )
      setAssetData(filteredData)
    }else {
      setAssetData(data)
    }
  },[searchQuery, data])
  return (
    <ScrollableTable
      tableContent={energy}
      colLength={62}
      headCellPadding={45}
      actionCellPadding={103}
      maxWidth={650}
      cellBorder="1px solid #e8ebf2;"
      fixedLeftHeaderTable={true}
      assetData={assetData}
      addField={addField}
      setAddField={setAddField}
      postRequest={handleAddAsset}
      deleteRequest = {handleDeleteAsset}
      putRequest={handleUpdateAsset}
    />
  )
}

export default EnergyDataTable