/** @format */

import { Button, Stack, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./scopeList.module.css";
import ScopeCardItem from "../UI/ScopeCardItem";
import {
  requestScopeList,
  removeScope,
} from "../../services/private/scope/ScopeService";
import DeleteModal from "../../modals/DeleteModal";
import { errorToast, successToast } from '../../utils/alerts';
import { useSelector } from "react-redux";

const ScopeList = ({ type }) => {
  const selectedYear = useSelector((state)=>state.years.selectedYear)
  const navigate = useNavigate();
  const [entities, setEntities] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const serverRequest = async (selectedYear) => {
    try {
      setLoading(true);
      const response = await requestScopeList(selectedYear.fy_start, selectedYear.fy_end, type);
      setEntities(response.data);
      setLoading(false);
    } catch (error) {
      errorToast(error.message)
      setLoading(false);
    }
  };

  const openDeleteModal = async (id) => {
    setSelectedItem(id);
    setDeleteModal(true);
  };

  const handelDeleteRow = async (id) => {
    try {
      await removeScope(id);
      successToast("Deleted Successfully")
      setDeleteModal(false);
      setSelectedItem(null);
      await serverRequest(selectedYear);
    } catch (error) {
      errorToast(error.message)
    }
  };
  useEffect(() => {
    serverRequest(selectedYear);
    // eslint-disable-next-line
  }, [selectedYear]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="24px"
      >
        <Typography variant="p" className={styles.pageHeadline}>
          Scope {type} Details
        </Typography>
        <Button
          variant="contained"
          sx={{ color: "#fff" }}
          onClick={() => navigate("add-scope-details")}
        >
          Add New Asset
        </Button>
      </Stack>
      {loading && (
        <div className="sk_dropdown">
              {Array(4)
                .fill(0)
                .map((skel, index) => (
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    style={{ marginTop: 15, background: "#f6f7fb" }}
                    height={100}
                    key={index}
                  />
                ))}
        </div>
      )}
      {entities.map((entity) => (
        <ScopeCardItem entity={entity} deleteRow={openDeleteModal} type={type}/>
      ))}

      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          handelClose={() => {
            setDeleteModal(false);
          }}
          label={"Are you sure you want to delete this?"}
          description={
            "By clicking delete button will permanently delete record"
          }
          deleteRow={handelDeleteRow}
          id={selectedItem}
        />
      )}
    </>
  );
};

export default ScopeList;
