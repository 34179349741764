/** @format */

import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BarChart from '../charts/BarChart';
import HorizontalBarChart from '../charts/HorizontalBarChart';
import { targetBarChart } from '../../services/private/targets/TargetService';

const TargetsAnalytics = () => {
  const [barData, setBarData] = useState([]);
  const chartData = {
    labels: barData.map((data) => data.year),
    datasets: [
      {
        label: 'Emissions Reduction',
        type: 'line',
        data: barData.map((data) => data.sum || data.totalEmission),
        borderColor: '#6DB3B5',
        fill: true,
        tension: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      {
        label: 'Emissions Reduction',
        type: 'bar',
        data: barData.map((data) => data.sum || data.totalEmission),
        backgroundColor: '#165BAA',
        barThickness: 32,
      },
    ],
  };

  async function fetchBarChartData() {
    try {
      const res = await targetBarChart();
      setBarData(res.data);
    } catch (err) {
      // console.log(err);
    }
  }

  useEffect(() => {
    fetchBarChartData();
  }, []);
  return (
    <Stack gap="24px">
      <HorizontalBarChart />
      <BarChart
        chartData={chartData}
        yAxisTitle="C0-2-Emissions [tC02e]"
        chartTitle="Emissions Reduction Targets.(C02E)"
        aspectRatio={5}
      />
    </Stack>
  );
};

export default TargetsAnalytics;
