/** @format */

import React, { useEffect, useState, useMemo } from 'react';

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  Select as MUISelect,
  IconButton,
} from '@mui/material';
// import Select from 'react-select';
import styles from '../../pages/dataVault/dataVault.module.css';
import { ArrowBack, UploadFile } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPropertyList } from '../../services/private/assetManagement/assetServices';
import { Country, State, City } from 'country-state-city';
import CustomReactSelect from '../../components/UI/CustomReactSelect';
import { successToast, errorToast } from '../../utils/alerts';
import { postAddNewAsset, postUpdateAsset } from '../../services/private/assetManagement/assetServices';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const initialAssetData = {
  name: '',
  property_type: '',
  country: null,
  state: null,
  city: null,
  street: '',
  construction_year: '',
  ownership: '',
  value: '',
  size: '',
  attachment: null,
};

const AddNewAsset = () => {
  const [assetData, setAssetData] = useState(initialAssetData);
  const [propertyList, setPropertyList] = useState([]);
  const [country, setSelectedCountry] = useState(null);
  const [state, setSelectedState] = useState(null);
  const [city, setSelectedCity] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [error, setError] = useState({
    status: false,
    message: '',
    attachment: false
  });
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state ? location.state.id : null
  const attachmentFormatter = (param) => {
    const arr = param.split('/');
    const n = arr.length;
    return arr[n - 1];
  };

  useEffect(() => {
    async function fetchPropertyList() {
      try {
        const propertyList = await getPropertyList();
        // console.log("property:", propertyList);
        setPropertyList(propertyList);
      } catch (err) {
        errorToast('Failed to fetch Property List!');
      }
    }
    fetchPropertyList();
  }, []);

  const handleSelectedCountry = (e) => {
    setSelectedCountry(e);
    setAssetData((prev) => ({
      ...prev,
      country: e.label,
    }));
  };
  const handleSelectedState = (e) => {
    setSelectedState(e);
    setAssetData((prev) => ({
      ...prev,
      state: e.label,
    }));
  };
  const handleSelectedCity = (e) => {
    setSelectedCity(e);
    setAssetData((prev) => ({
      ...prev,
      city: e.label,
    }));
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setError((prevState) => ({ ...prevState, attachment: false, message: "" }))
      setAttachment(selectedFile);
      setAssetData((prev) => ({ ...prev, attachment: selectedFile }));
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    console.log(attachment);
    if (droppedFile) setAttachment(droppedFile);
  };

  const handleDragOver = (event) => event.preventDefault();

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setError((prevState)=> ({...prevState, status: false, message:""}))
    // Check if the field requires an integer
    if (name === 'ownership' || name === 'value' || name === "construction_year") {
      // Only update with integer values, otherwise set to empty
      if (!/^\d*$/.test(value)) {
        setAssetData({ ...assetData, [name]: '' });
        return;
      }
    }

    setAssetData({ ...assetData, [name]: value });
  };


  const countries = useMemo(
    () =>
      Country.getAllCountries().map((country) => ({
        label: country.name,
        value: country.isoCode,
      })),
    []
  );

  const states = useMemo(
    () =>
      country
        ? State.getStatesOfCountry(country.value).map((state) => ({
          label: state.name,
          // name: state.name,
          value: state.isoCode,
        }))
        : [],
    [country]
  );

  // console.log("state", states)
  const cities = useMemo(
    () =>
      state
        ? City.getCitiesOfState(country.value, state.value).map(
          (city) => ({
            label: city.name,
            value: city.name,
          })
        )
        : [],
    [country, state]
  );
  // console.log("cities", cities)


  const handleData = async () => {
    const formData = new FormData();

    // Check if required fields are missing
    if (!assetData.name || !assetData.property_type || !assetData.size || !assetData.value || !assetData.construction_year || !assetData.ownership || !assetData.street) {
      const errorMessage = "All fields are required!";
      setError((prevState) => ({ ...prevState, status: true, message: errorMessage }));
      errorToast(errorMessage);
      return;
    }

    // Validate the construction_year format
    if (!/^\d{4}$/.test(assetData.construction_year)) {
      const errorMessage = "Please enter year in YYYY format!";
      setError((prevState) => ({ ...prevState, status: true, message: errorMessage }));
      errorToast(errorMessage);
      return;
    }
    // Check if file is missing
    if (!assetData.attachment) {
      const errorMessage = "Please import a file!";
      setError((prevState) => ({ ...prevState, attachment: true, message: errorMessage }));
      // errorToast(errorMessage);
      return;
    }


    // Append data to formData
    formData.append('name', assetData.name);
    formData.append('property_type', assetData.property_type);
    formData.append('country', assetData.country);
    formData.append('state', assetData.state);
    formData.append('city', assetData.city);
    formData.append('size', assetData.size);
    formData.append('value', assetData.value);
    formData.append('ownership', assetData.ownership);
    formData.append('construction_year', assetData.construction_year);
    formData.append('street', assetData.street);
    formData.append('attachment', assetData.attachment);
    // Send data and navigate
    const res =  location.state ? await postUpdateAsset(formData, id) : await postAddNewAsset(formData);
    successToast(res.message);
    navigate(-1);
    // console.log("data to submit:", assetData);
  };

  useEffect(() => {
    if (location.state) {
      // console.log("id:", id);
      setAssetData(location.state);
      const selectedCountry2 = countries.find(
        (c) => c.label === location.state.country
      );
      setSelectedCountry(selectedCountry2);

      const selectedState2 = selectedCountry2
        ? State.getStatesOfCountry(selectedCountry2.value).find(
          (s) => s.name === location.state.state
        )
        : null;
        if(selectedState2){
          setSelectedState({
            label: selectedState2.name,
            value: selectedState2.isoCode
          });
        }


      // console.log("selectedCountry2", selectedCountry2)
      // console.log("selectedState2", selectedState2)

      const selectedCity2 = selectedState2 ? City.getCitiesOfState(selectedCountry2.value, selectedState2.isoCode).find(
        (c) => c.name === location.state.city
      ) : null
      // console.log("selectedCity2:", selectedCity2);
      if(selectedCity2){
        setSelectedCity({
          label: selectedCity2.name,
          value: selectedCity2.name
        });
      }

    }
  }, [location.state, countries]);
  // console.log(state);
  // console.log(city);
  return (
    <Box className={styles.dataVaultContainer}>
      {/* <AssetNavBar /> */}
      <Stack className={styles.assetContainer} p={3}>
        <Stack alignItems={'center'} direction="row" gap="16px" mb={2}>
          <IconButton onClick={() => navigate('/asset-management')}>
            <ArrowBack />
          </IconButton>
          <Typography className={styles.pageHeading} variant="h5">
            {location.state ? "Edit Asset Details" : "Add Asset Details"}
          </Typography>
        </Stack>
        <Stack gap={3}>
          {/* Asset Name and Property Type */}
          <Stack direction="row" gap={3}>
            <TextField
              label="Asset Name"
              sx={{ width: '50%' }}
              onChange={handleChange}
              size="small"
              name="name"
              value={assetData.name || ''}
            />
            <FormControl sx={{ width: '50%', fontFamily: 'inherit' }}>
              <InputLabel id="property-label" className={styles.inputLabel}>
                Property Type
              </InputLabel>
              <MUISelect
                labelId="property-label"
                id="property-type"
                name="property_type"
                onChange={handleChange}
                input={<OutlinedInput label="Property Type" />}
                MenuProps={MenuProps}
                size="small"
                value={assetData.property_type || ''}
              >
                {propertyList.map((item, ind) => (
                  <MenuItem key={ind} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </MUISelect>
            </FormControl>
          </Stack>

          {/* Country, State, City, and Street Address */}
          <Stack direction="row" gap={3}>
            <Box sx={{ width: '50%' }} className={styles.reactSelect}>
              <CustomReactSelect
                options={countries}
                value={country}
                onChange={(e) => handleSelectedCountry(e)}
                placeholder="Country"
              />
            </Box>
            <Box sx={{ width: '50%' }} className={styles.reactSelect}>
              <CustomReactSelect
                options={states}
                value={state}
                onChange={(e) => handleSelectedState(e)}
                placeholder="State / Province"
                isDisabled={!country}
              />
            </Box>
          </Stack>
          <Stack direction="row" gap={3}>
            <Box sx={{ width: '50%' }} className={styles.reactSelect}>
              <CustomReactSelect
                options={cities}
                value={city}
                onChange={(e) => handleSelectedCity(e)}
                placeholder="City"
                isDisabled={!state}
              />
            </Box>
            <TextField
              label="Street Address"
              sx={{ width: '50%' }}
              onChange={handleChange}
              size="small"
              name="street"
              value={assetData.street || ''}
            />
          </Stack>

          {/* Other Asset Fields */}
          <Stack direction="row" gap={3}>
            <TextField
              label="Construction Year"
              sx={{ width: '50%' }}
              onChange={handleChange}
              size="small"
              name="construction_year"
              value={assetData.construction_year || ''}
            />
            <TextField
              label="% of Ownership"
              sx={{ width: '50%' }}
              onChange={handleChange}
              size="small"
              name="ownership"
              value={assetData.ownership || ''}
            />
          </Stack>
          <Stack direction="row" gap={3}>
            <TextField
              label="Gross Asset Value (GAV) $"
              sx={{ width: '50%' }}
              onChange={handleChange}
              size="small"
              name="value"
              value={assetData.value || ''}
            />
            <TextField
              label="Asset Size (GFA)"
              sx={{ width: '50%' }}
              onChange={handleChange}
              size="small"
              name="size"
              value={assetData.size || ''}
            />
          </Stack>
        </Stack>

        {/* File Upload Section */}
        <Typography className={styles.pageHeading} mt={3} mb={2} variant="h6">
          Upload Supporting Attachment
        </Typography>
        <Typography className={styles.listLabel}>
          Please provide financial evidence of asset Gross Asset Value of each
          asset. Financial evidence must sufficiently support:
        </Typography>
        <ul className={styles.listItems}>
          <li className={styles.listItem}>
            Property Sub-Types represented in the portfolio;
          </li>
          <li className={styles.listItem}>
            Numbers of assets per Property Sub-Type;
          </li>
          <li className={styles.listItem}>Floor Area per Property Sub-Type</li>
        </ul>
        <Stack className={styles.importContainer}>
          <Typography variant="h5" className={styles.import}>
            Import Excel File
          </Typography>
          <Stack
            className={styles.importBox}
            alignItems="center"
            gap={1}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <UploadFile color="primary" sx={{ fontSize: 40 }} />
            <Stack direction="row" gap="4px" alignItems="center">
              <Typography
                variant="span"
                className={styles.clickToUpload}
                onClick={() => document.getElementById('fileInput').click()}
              >
                Click to upload
              </Typography>
              <Typography variant="span" className={styles.drag}>
                or drag and drop
              </Typography>
            </Stack>
            <Typography variant="p" className={styles.fileType}>
              XLS, XLSX (max. 3MB)
            </Typography>
            <input
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              accept=".xls,.xlsx"
              onChange={handleFileChange}
            />
            {assetData.attachment && (
              <Typography variant="body2">
              Selected File: {
                location.state && typeof assetData.attachment === 'string'
                  ? attachmentFormatter(assetData.attachment)
                  : assetData.attachment.name
              }
            </Typography>
            )}
            {/* {assetData.attachment && (
              <Typography variant="body2">
                Uploaded File:{ attachmentntFormatter(assetData.attachment)}
              </Typography>
            )} */}
            {error.attachment && (
              <Typography color={"error"}>{error.message}</Typography>
            )}
          </Stack>
        </Stack>

        {/* Save/Cancel Buttons */}
        <Stack direction="row" gap={2} alignItems="center">
          <Button
            variant="contained"
            sx={{ color: 'white' }}
            onClick={handleData}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate('/asset-management')}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
      <style>
        {`
        .css-1nmdiq5-menu {      
          z-index:99999999999;
          color: inherit;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5;
          letter-spacing: 0.00938em;
         }
        .css-1dimb5e-singleValue, 
        .css-1jqq78o-placeholder, 
        .css-166bipr-Input[data-value] {
          color: rgba(0, 0, 0, 0.6);
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.4375em;
          letter-spacing: 0.00938em;
        }
   
       `}
      </style>
    </Box>
  );
};

export default AddNewAsset;
