/** @format */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import styles from './dataVaultQuestionnaire.module.css';
import {  Upload } from '@mui/icons-material';
import {
  getDataVault,
  postDataVault,
  postDataVaultFileUploadSub,
} from '../../../services/private/dataVault/dataVaultService';
import { errorToast, successToast } from '../../../utils/alerts';

const DataVaultQuestionnaire3 = ({ tab, type }) => {
  const [isLoading, setIsLoading] = useState(true);
  const fileInputRef = useRef([]);
  const [data, setData] = useState([]);
  // const [selectedFile, setSelectedFile] = useState([]);
  const [fileData, setFileData] = useState([]);
  const maxWordsLimit = 250;

  // Fetch Data
  const serverRequest = useCallback(async () => {
    try {
      setIsLoading(true);
      const rep = await getDataVault(tab, type);
      setData(rep.data);
      setIsLoading(false);
    } catch (error) {
      errorToast(error.message);
      setIsLoading(false);
    }
  }, [tab, type]);

  const resetHandler = () => {
    serverRequest();
  };

  // Handle Radio Button Input
  const handleRadio = (id, val) => {
    setData((prevData) =>
      prevData.map((item) => (id === item.id ? { ...item, answer: val } : item))
    );
  };

  const handleInputChange = (param, newValue, inputType) => {
    const updatedData = data.map((item) => {
      // Check top-level question
      if (item.id === param.id) {
        if (inputType === 'checkbox') {
          const updatedAnswer = item.answer ? null : true; // Toggle logic for checkbox

          return { ...item, answer: updatedAnswer }; // Update the answer directly
        } else if (inputType === 'text') {
          const currentText = item.answer2 || ''; // Current text value
          const newWordCount = countWords(newValue); // Word count of the new input

          // Check if the new text is shorter (indicating characters are being removed)
          const isRemovingCharacters = newValue.length < currentText.length;

          // Allow text update if reducing characters or within the word limit
          if (isRemovingCharacters || newWordCount <= maxWordsLimit) {
            return {
              ...item,
              answer2: newValue, // Update the text directly
              disableTextArea:
                !isRemovingCharacters && newWordCount > maxWordsLimit, // Disable if over the limit and not removing
            };
          } else {
            return {
              ...item,
              disableTextArea: true, // Disable textarea when limit is exceeded
            };
          }
        }
      }

      // Check sub-level questions if they exist
      if (item.sub) {
        const updatedSub = item.sub.map((qs) => {
          if (qs.id === param.id) {
            if (inputType === 'checkbox') {
              const updatedAnswer = {
                question_id: param.id,
                answer1: qs.answer.answer1 ? null : true,
                answer2: qs.answer.answer1 ? '' : qs.answer.answer2,
              };

              return { ...qs, answer: updatedAnswer };
            } else if (inputType === 'text') {
              const currentText = qs.answer.answer2 || ''; // Current text value
              const newWordCount = countWords(newValue); // Word count of the new input
              const isRemovingCharacters = newValue.length < currentText.length;

              // Allow text update if reducing characters or within the word limit
              if (isRemovingCharacters || newWordCount <= maxWordsLimit) {
                return {
                  ...qs,
                  answer: {
                    ...qs.answer,
                    answer2: newValue, // Update the text directly
                  },
                  disableTextArea:
                    !isRemovingCharacters && newWordCount > maxWordsLimit, // Disable if over the limit and not removing
                };
              } else {
                return {
                  ...qs,
                  disableTextArea: true, // Disable textarea when limit is exceeded
                };
              }
            }
          }

          if (qs.subQuestions) {
            const updatedSubQuestions = qs.subQuestions.map((q) => {
              if (q.id === param.id) {
                if (inputType === 'checkbox') {
                  const updatedAnswer = {
                    ...q.answer,
                    answer1: q.answer.answer1 ? null : true,
                    answer2: q.answer.answer1 ? '' : q.answer.answer2,
                  };
                  return { ...q, answer: updatedAnswer };
                } else if (inputType === 'text') {
                  const currentText = q.answer.answer2 || ''; // Current text value
                  const newWordCount = countWords(newValue); // Word count of the new input
                  const isRemovingCharacters =
                    newValue.length < currentText.length;

                  // Allow text update if reducing characters or within the word limit
                  if (isRemovingCharacters || newWordCount <= maxWordsLimit) {
                    return {
                      ...q,
                      answer: {
                        ...q.answer,
                        answer2: newValue, // Update the text directly
                      },
                      disableTextArea:
                        !isRemovingCharacters && newWordCount > maxWordsLimit, // Disable if over the limit and not removing
                    };
                  } else {
                    return {
                      ...q,
                      disableTextArea: true, // Disable textarea when limit is exceeded
                    };
                  }
                }
              }
              return q; // Return unchanged q if no matches found
            });
            return { ...qs, subQuestions: updatedSubQuestions };
          }

          return qs;
        });

        return { ...item, sub: updatedSub };
      }

      return item; // Return unchanged item if no matches found
    });

    setData(updatedData); // Update state
  };

  const handleFileSelect = (index) => {
    fileInputRef.current[index].click();
  };

  const handleFileInput = (file, question_id) => {
    setFileData((prev) => {
      // Check if the question_id already exists in the fileData array
      const existingIndex = prev.findIndex(
        (item) => item.question_id === question_id
      );

      if (existingIndex !== -1) {
        // If found, create a new array with the updated file for the same question_id
        const updatedData = [...prev];
        updatedData[existingIndex] = { file, question_id };
        return updatedData;
      } else {
        // If not found, add the new file with question_id to the array
        return [...prev, { file, question_id }];
      }
    });
  };

  // Function to check if the word count is within the allowed limit
  const isValidWordCount = (item) => {
    const maxWordsLimit = 250; // Your max word limit

    // Check top-level answer
    if (item.answer2 && countWords(item.answer2) > maxWordsLimit) {
      return false; // If the word count exceeds the limit, return false
    }

    // Check sub-level questions
    if (item.sub) {
      for (let qs of item.sub) {
        if (
          qs.answer.answer2 &&
          countWords(qs.answer.answer2) > maxWordsLimit
        ) {
          return false; // Return false if any sub-question exceeds the word limit
        }

        // Check sub-sub-level questions
        if (qs.subQuestions) {
          for (let q of qs.subQuestions) {
            if (
              q.answer.answer2 &&
              countWords(q.answer.answer2) > maxWordsLimit
            ) {
              return false; // Return false if any sub-sub-question exceeds the word limit
            }
          }
        }
      }
    }

    return true; // Return true if all word counts are valid
  };

  const handleSubmit = async () => {
    // Validate all items before proceeding
    const docFilteredData = removeDocTypeQuestions(data);
    const isWordCountValid = data.every(isValidWordCount);

    if (!isWordCountValid) {
      errorToast('Word limit exceeded. Please check your inputs.');
      return; // Exit the function if validation fails
    }

    const payload = docFilteredData.map((item) => {
      return {
        question_id: String(item.id),
        answer: item.answer, // Assuming this is a string like "true"
        sub: item.sub
          ? item.sub.reduce((acc, qs) => {
              // Add the current sub question to the array
              const currentSub = {
                question_id: String(qs.id),
                answer1: qs.answer.answer1, // Directly assign answer1
                answer2: qs.answer.answer2, // Directly assign answer2 if it exists
              };

              // Add any subQuestions to the same array
              const subQuestions = qs.subQuestions
                ? qs.subQuestions.map((q) => {
                    return {
                      question_id: String(q.id),
                      answer1:
                        q.question_type === 'checkbox_input'
                          ? q.answer.answer2
                            ? q.answer.answer1
                            : null
                          : q.answer.answer1, // Directly assign answer1
                      answer2: q.answer.answer2, // Directly assign answer2 if it exists
                    };
                  })
                : [];

              // Combine currentSub and subQuestions into the accumulator array
              return acc.concat(currentSub, ...subQuestions);
            }, [])
          : [],
      };
    });

    try {
      // Proceed with the API request using async/await
      await postDataVault(payload);

      serverRequest(); // Proceed with the server request if postDataVault is successful
      successToast('Data submitted successfully');

      if (fileData?.length > 0) {
        // Handle file uploads
        for (let fileItem of fileData) {
          const formData = new FormData(); // Ensure a fresh formData for each file
          formData.append('files', fileItem.file);
          // Call the API with the correct file and its corresponding question_id
          await postDataVaultFileUploadSub(formData, fileItem.question_id);
        }
      }
    } catch (err) {
      // Handle errors from any part of the try block
      errorToast(err.message); // Display the error message
    }
  };

  useEffect(() => {
    serverRequest();
  }, [serverRequest]);

  return (
    <Box
      p={3}
      sx={{ borderRadius: '8px', background: '#F6F7FB' }}
      className={styles.leadershipQuestionnaireContainer}
    >
      {tab === 5 && type === 'leadership' && (
        <>
          <Stack gap={1} mb={2}>
            <Typography variant="p" className={styles.tab5}>
              An Environmental Management System (EMS) is an internal framework
              that structures all procedures, projects and tactics into a
              cohesive program which aligns the sustainability efforts at entity
              level. An EMS can assist entities in managing and improving their
              environmental performance, complying with environmental laws and
              regulations, identifying financial savings through more efficient
              operating practices, and improving the standing of the business
              with staff, client companies, partner organizations and other
              stakeholders.
            </Typography>
            <Typography variant="p" className={styles.tab5}>
              Use of an aligned or certified EMS framework provides assurance to
              both the business and external stakeholders that environmental
              impacts are measured and acted upon using a recognized and proven
              methodology. Periodic reviews of the EMS ensure its continuing
              suitability and effectiveness for the entity.
            </Typography>
          </Stack>
          {!isLoading &&
            data?.map((item, ind) => (
              <Box key={ind}>
                <FormControl>
                  <FormLabel className={styles.tab5}>{item.question}</FormLabel>
                  {item.input_type === 'radio' && (
                    <RadioGroup
                      row
                      value={item.answer} // Bind the value based on the answer
                      onChange={(e) => handleRadio(item.id, e.target.value)}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  )}
                </FormControl>
                {item.sub.map((qs, index) => (
                  <Box
                    className={
                      item.input_type === 'title' ||
                      item.question_type === 'title'
                        ? styles.testing
                        : ''
                    }
                    key={index}
                  >
                    {qs.question_type === 'title' && (
                      <Typography variant="p" className={styles.questionLabel}>
                        {qs.question}
                      </Typography>
                    )}
                    {qs.subQuestions.map((q, ind) => (
                      <Stack
                        key={ind}
                        direction="row"
                        my={1}
                        alignItems="center"
                      >
                        {q.input_type === 'checkbox' ||
                        q.question_type === 'checkbox' ? (
                          <Stack>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                              }}
                            >
                              <Checkbox
                                checked={q.answer?.answer1 || false}
                                onChange={() =>
                                  handleInputChange(q, null, 'checkbox')
                                }
                                name={`checkbox-${q.id}`}
                              />

                              <Typography
                                variant="p"
                                className={styles.questionText}
                                pt={1}
                              >
                                {q.question}
                              </Typography>
                            </Box>
                          </Stack>
                        ) : (
                          q.input_type === 'checkbox_input' ||
                          (q.question_type === 'checkbox_input' && (
                            <>
                              <Checkbox
                                checked={q.answer?.answer1 || false}
                                onChange={() =>
                                  handleInputChange(q, null, 'checkbox')
                                }
                                name={`checkbox-${q.id}`}
                              />
                              <Typography
                                variant="p"
                                className={styles.questionText}
                              >
                                {q.question}
                              </Typography>
                              <Box sx={{ marginLeft: '10px' }}>
                                <TextField
                                  variant="standard"
                                  onChange={(e) => {
                                    handleInputChange(
                                      q,
                                      e.target.value,
                                      'text'
                                    );
                                  }}
                                  name={q.id}
                                  disabled={!q.answer?.answer1}
                                  value={q.answer?.answer2}
                                />
                              </Box>
                            </>
                          ))
                        )}
                      </Stack>
                    ))}
                  </Box>
                ))}
              </Box>
            ))}
        </>
      )}
      {!isLoading && (
        <>
          {tab !== 5 && type === 'leadership' && (
            <Typography variant="p" className={styles.questionLabel}>
              Please select the following issues which your organization has
              active management and visibility over.
            </Typography>
          )}
          {data?.map((item, index) => (
            <Stack key={item.id}>
              {item.input_type === 'title' && (
                <Typography variant="p" className={styles.questionLabel}>
                  {item.question}
                </Typography>
              )}
              <Stack direction="row" my={1} alignItems="center">
                {item.input_type === 'checkbox' ||
                item.question_type === 'checkbox' ? (
                  <Stack>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                      <Checkbox
                        checked={item.answer || false}
                        onChange={() =>
                          handleInputChange(item, null, 'checkbox')
                        }
                        name={`checkbox-${item.id}`}
                      />

                      <Typography
                        variant="p"
                        className={styles.questionText}
                        pt={1}
                      >
                        {item.question}
                      </Typography>
                    </Box>
                  </Stack>
                ) : item.input_type === 'input' ||
                  item.question_type === 'input' ? (
                  <Box mt={2} mb={2}>
                    <Typography
                      variant="p"
                      className={styles.questionText}
                      sx={{ marginRight: '10px' }}
                    >
                      {item.name}
                    </Typography>
                    <TextField
                      variant="standard"
                      onChange={(e) =>
                        handleInputChange(item, e.target.value, 'text')
                      }
                      name={item.id}
                      value={item.answer.answer || ''}
                    />
                  </Box>
                ) : (
                  (item.input_type === 'doc' ||
                    item.question_type === 'doc') && (
                    <>
                      <Typography variant="p" className={styles.questionLabel}>
                        {item.question}
                      </Typography>
                      <input
                        type="file"
                        ref={(el) => (fileInputRef.current[index] = el)}
                        style={{ display: 'none' }}
                        onChange={(e) =>
                          handleFileInput(e.target.files[0], item.id)
                        }
                        // setSelectedFile([...e.target.files])}
                      />

                      <Button
                        startIcon={<Upload color="primary" />}
                        sx={{ marginLeft: '8px' }}
                        onClick={() => handleFileSelect(index)}
                      >
                        <Typography>Upload File</Typography>
                      </Button>
                      {fileData.length > 0 &&
                        fileData.some(
                          (item) => item.question_id === item.id
                        ) && (
                          <Typography variant="body1">
                            file is uploaded
                          </Typography>
                        )}
                    </>
                  )
                )}
              </Stack>
              {item.sub?.length > 0 &&
                item.sub?.map((qs, ind) => (
                  <Box
                    className={
                      qs.input_type === 'input' || qs.question_type === 'input'
                        ? styles.testing
                        : ''
                    }
                    key={qs.id}
                  >
                    <Stack
                      direction="row"
                      gap={tab !== 5 && type !== 'leadership' && '12px'}
                      my={tab !== 5 && type !== 'leadership' && 1.5}
                      alignItems="center"
                    >
                      {qs.input_type === 'checkbox' ||
                      qs.question_type === 'checkbox' ? (
                        <Stack>
                          <Box
                            sx={{ display: 'flex', alignItems: 'flex-start' }}
                          >
                            <Checkbox
                              checked={qs.answer?.answer1}
                              onChange={() =>
                                handleInputChange(qs, null, 'checkbox')
                              }
                              name={`checkbox-${qs.id}`}
                            />
                            {qs?.question?.includes('\n') ? (
                              <Typography
                                variant="p"
                                className={styles.questionText}
                                pt={1}
                              >
                                {qs.question.split('\n')[0]}
                              </Typography>
                            ) : (
                              <Typography
                                variant="p"
                                className={styles.questionText}
                                pt={1}
                              >
                                {qs.question}
                              </Typography>
                            )}
                          </Box>

                          {/* to render the list */}
                          {qs.question.includes('\n') && (
                            <ul className={styles.list}>
                              {qs.question
                                .split('\n')
                                .splice(1)
                                .map(
                                  (item2, index) =>
                                    item2 && (
                                      <li
                                        className={styles.questionText}
                                        key={index}
                                      >
                                        {item2}
                                      </li>
                                    )
                                )}
                            </ul>
                          )}
                        </Stack>
                      ) : qs.input_type === 'checkbox_input' ||
                        qs.question_type === 'checkbox_input' ? (
                        <>
                          <Checkbox
                            checked={qs.answer?.answer1 || false}
                            onChange={() =>
                              handleInputChange(qs, null, 'checkbox')
                            }
                            name={`checkbox-${qs.id}`}
                          />
                          <Typography
                            variant="p"
                            className={styles.questionText}
                          >
                            {qs.question}
                          </Typography>
                          <Box sx={{ marginLeft: '10px' }}>
                            <TextField
                              variant="standard"
                              onChange={(e) =>
                                handleInputChange(qs, e.target.value, 'text')
                              }
                              name={qs.id}
                              disabled={!qs.answer?.answer1}
                              value={qs.answer?.answer2}
                            />
                          </Box>
                        </>
                      ) : qs.input_type === 'input' ||
                        qs.question_type === 'input' ? (
                        <Stack
                          direction={
                            tab !== 2 && type !== 'decision' ? 'row' : 'column'
                          }
                          mt={2}
                          mb={2}
                        >
                          <Typography
                            variant="p"
                            className={styles.questionText}
                            sx={{ marginRight: '10px' }}
                          >
                            {qs.question}
                          </Typography>
                          <TextField
                            variant="standard"
                            onChange={(e) =>
                              handleInputChange(qs, e.target.value, 'text')
                            }
                            name={qs.id}
                            value={qs.answer.answer2 || ''}
                            multiline
                            // disabled={qs.disableTextArea}
                          />
                          {qs.disableTextArea && (
                            <Typography variant="p" sx={{ color: 'orangered' }}>
                              {`The maximum word limit is ${maxWordsLimit}!`}
                            </Typography>
                          )}
                        </Stack>
                      ) : (
                        (qs.input_type === 'doc' ||
                          qs.question_type === 'doc') && (
                          <>
                            <Typography
                              variant="p"
                              className={styles.questionLabel}
                            >
                              {qs.question}
                            </Typography>
                            <input
                              type="file"
                              ref={(el) => (fileInputRef.current[ind] = el)}
                              style={{ display: 'none' }}
                              onChange={(e) =>
                                handleFileInput(e.target.files[0], qs.id)
                              }
                            />
                            <Button
                              startIcon={<Upload color="primary" />}
                              sx={{ marginLeft: '8px' }}
                              onClick={() => handleFileSelect(ind)}
                            >
                              <Typography>Upload File</Typography>
                            </Button>
                            {fileData.length > 0 &&
                              fileData.some(
                                (item) => item.question_id === qs.id
                              ) && (
                                <Typography variant="body1">
                                  {`${
                                    fileData.filter(
                                      (item) => item.question_id === qs.id
                                    )[0]?.file.name
                                  } has been uploaded successfully`}
                                </Typography>
                              )}
                          </>
                        )
                      )}
                    </Stack>
                    {qs.input_type === 'input' && (
                      <>
                        <Typography variant="p" className={styles.questionText}>
                          {qs.question}
                        </Typography>
                        <Box mt={2} mb={2} sx={{ marginLeft: '8px' }}>
                          <TextField
                            variant="standard"
                            onChange={(e) =>
                              handleInputChange(qs, e.target.value, 'text')
                            }
                            name={qs.id}
                            value={qs.answer}
                          />
                        </Box>
                      </>
                    )}
                    {qs.input_type === 'doc' && (
                      <>
                        <Typography
                          variant="p"
                          className={styles.questionLabel}
                        >
                          {qs.question}
                        </Typography>
                        <input
                          type="file"
                          ref={(el) => (fileInputRef.current[ind] = el)}
                          style={{ display: 'none' }}
                          onChange={(e) =>
                            handleFileInput(e.target.files[0], qs.id)
                          }
                        />
                        <Button
                          startIcon={<Upload color="primary" />}
                          sx={{ marginLeft: '8px' }}
                          onClick={() => handleFileSelect(ind)}
                        >
                          <Typography>Upload File</Typography>
                        </Button>
                        {fileData.length > 0 &&
                          fileData.some(
                            (item) => item.question_id === qs.id
                          ) && (
                            <Typography variant="body1">
                              file is uploaded
                            </Typography>
                          )}
                      </>
                    )}
                  </Box>
                ))}
            </Stack>
          ))}
        </>
      )}
      {isLoading && <p>Loading...</p>}
      <Stack direction="row" gap="16px" mt={3}>
        <Button
          variant="contained"
          sx={{ color: '#fff' }}
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={resetHandler}>
          Clear
        </Button>
      </Stack>
    </Box>
  );
};

export default DataVaultQuestionnaire3;

function countWords(str) {
  const trimmedStr = str.trim();

  // Check if the string is empty after trimming
  if (trimmedStr === '') {
    return 0;
  }

  // Split the string by one or more spaces and count the resulting array's length
  const words = trimmedStr.split(/\s+/);
  return words.length;
}

function removeDocTypeQuestions(data) {
  return data
    .filter((item) => {
      return item.question_type !== 'doc';
    }) // Remove items with 'doc' type
    .map((item) => {
      // Recursively check if there are subQuestions or sub arrays
      if (item.subQuestions && item.subQuestions.length > 0) {
        item.subQuestions = removeDocTypeQuestions(item.subQuestions);
      }
      if (item.sub && item.sub.length > 0) {
        item.sub = removeDocTypeQuestions(item.sub);
      }
      return item;
    });
}
