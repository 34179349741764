import React, { useEffect, useState } from 'react'
import { ghg } from '../../constant/assetManagement'
import ScrollableTable from './ScrollableTable'
import { addGhgAsset, deleteGhgAsset, getGhgAsset, updateGhgAsset } from '../../services/private/assetManagement/assetServices'
import useGroupedAssets from '../../hooks/useGroupedAssets'
const GhgDataTable = ({ addField, setAddField, searchQuery }) => {
  const [trigger, setTrigger] = useState(0)
  const data = useGroupedAssets(getGhgAsset, trigger)
  const [assetData, setAssetData ]= useState(data)
  const handleAddAsset = async (formData) => {
    const res = await addGhgAsset(formData)
    if (res.type === "RXSUCCESS") {
      setTrigger((prev) => prev + 1)
    }
    return res
  }
  const handleDeleteAsset = async (id) => {
    const res = await deleteGhgAsset(id)
    if (res.type === "RXSUCCESS") {
      setTrigger((prev) => prev + 1); // Toggle refreshTrigger to re-fetch data
    }
    return res
  }
  const handleUpdateAsset = async (payload, id) => {
    const res = await updateGhgAsset(payload, id)
    if (res.type === "RXSUCCESS") {
      setTrigger((prev) => prev + 1)
    }
    return res
  }
  useEffect(()=>{
    if(searchQuery){
      const searchTerm = searchQuery.toLowerCase()
      const filteredData = data.filter((item)=>
        item.asset_name.toLowerCase().includes(searchTerm)
      )
      setAssetData(filteredData)
    }else {
      setAssetData(data)
    }
  },[searchQuery, data])
  return (
    <div>
      <ScrollableTable
        tableContent={ghg}
        colLength={11}
        headCellPadding={30.7}
        actionCellPadding={74.7}
        maxWidth={650}
        cellBorder="1px solid #e8ebf2;"
        fixedLeftHeaderTable={true}
        assetData={assetData}
        addField={addField}
        setAddField={setAddField}
        postRequest={handleAddAsset}
        deleteRequest={handleDeleteAsset}
        putRequest={handleUpdateAsset}
      />
    </div>
  )
}

export default GhgDataTable