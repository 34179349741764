/** @format */

import {
  ArrowBack,
  Delete,
  ModeEditOutline,
  Visibility,
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
// import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import styles from "../../pages/dataVault/dataVault.module.css";
import classes from "./assetManagement.module.css";
import AssetNavBar from "../UI/AssetNavBar";
import DeleteModal from "../../modals/DeleteModal";
import { deleteDoc, getTenant, updateTenant, addDoc } from "../../services/private/assetManagement/assetServices";
import { successToast } from "../../utils/alerts";
import TenantModal from "../../modals/TenantModal";
import AddDocsModal from "../../modals/AddDocsModal";
import config from '../../config/config.json'
const ViewTenants = () => {
  const navigate = useNavigate();
  const { assetId } = useParams();
  const { tenantId } = useParams()
  // const location = useLocation()
  // const { tenant: initialTenant, tenantDocs: initialTenantDocs } = location.state || {};

  const [tenantDocs, setTenantDocs] = useState([])
  const [tenant, setTenant] = useState([]);
  // update modal states 
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [selectedTenant, setSelectedTenant] = useState(null)
  // delete doc modal states 
  const [selectedDoc, setSelectedDoc] = useState(null)
  const [dialog, setDialog] = useState(false)
  // add doc modal states 
  const [documentModal,  setDocumentModal] = useState(false)
  
  
  // fetch tenant data
  const serverRequest = useCallback( async (tenantId) => {
      const entity = await getTenant(tenantId);
      setTenant(entity.data.tenant);
      setTenantDocs(entity.data.tenantDocs);  
    }, [])
  useEffect(() => {
    serverRequest(tenantId)
  }, [serverRequest, tenantId])

  const handleGoBack = () => {
    const newPath = `/asset-management/view-assets/${assetId}`;
    navigate(newPath);
  };
  
  ////////////// delete doc functions /////////////////////
  const handleDeleteModalOpen = async (id) => {
    setSelectedDoc(id)
    setDialog(true)
  }
  const handleDeleteModalClose = async () => {
    setDialog(false)
  }
  const handleDelete = async (id) => {
    try {
      setDialog(false)
      await deleteDoc(id)
      setTenantDocs((prevDocs) => prevDocs.filter((doc) => doc.id !== id));
      successToast("Deleted Successfully")
    } catch (error) {
      successToast(error.message)
    }
  }
  /////////// update functions ///////////////
  const handleEditClick = (tenant) => {
    setSelectedTenant(tenant); // Set tenant to be edited
    setOpenUpdateModal(true);  // Open modal in update mode
  };
  const handleCloseUpdateModal = () => {
    setOpenUpdateModal(false)
    setSelectedTenant(null)
  }
  const handleUpdate = async (payload) => {
    try {
      await updateTenant(payload, tenantId)
      successToast("Tenant updated successfully")
      setOpenUpdateModal(false)
      serverRequest(tenantId)
    } catch (error) {
      successToast(error?.message)
      setOpenUpdateModal(false)
    }
  }

  /////////////////// add doc modal functions //////////////////
  const handleOpenDocModal = async () => {
    setDocumentModal(true)
  }
  const handleCloseDocModel = async () =>{
    setDocumentModal(false)
  }
  const handleDOcSubmit = async(payload)=> {
    try {
      await addDoc(payload, tenantId)
      successToast("Document added successfully")
      serverRequest(tenantId)
    } catch (error) {
      successToast(error?.message)
      setOpenUpdateModal(false)
    }
    
  } 
  useEffect(()=>{
    console.log(222, tenantDocs);

  },[tenantDocs])
  //////////////////////////////// format date //////////////////////////////
  function formatDate(dateString) {
    const date = new Date(dateString);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  return (
    <Stack className={styles.dataVaultContainer}>
      <AssetNavBar />
      <Stack
        p={3}
        gap={3}
        className={classes.tenantContainer}
        sx={{ borderRadius: "8px", background: " #FFF" }}
      >
        <Stack direction="row" gap={2}>
          <IconButton
            size="small"
            className={styles.backArrow}
            onClick={handleGoBack}
          >
            <ArrowBack />
          </IconButton>
          {tenant.map((item) => (
            <Typography key={item.id} variant="h6">{item.tenant_name}</Typography>
          ))}

        </Stack>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.tableHeadRow}>
                <TableCell className={classes.tableHeadCell}>
                  Tenant ID
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Tenant name
                </TableCell>
                <TableCell className={classes.tableHeadCell}>Floor</TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Suite No.
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Area Leased
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Commencement Date
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Expiry Date
                </TableCell>
                <TableCell className={classes.tableHeadCell}>Rent</TableCell>
                <TableCell className={classes.tableHeadCell}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {tenant?.map((item) => (
                <TableRow className={classes.tableBodyRow} key={item.id}>
                  <TableCell className={classes.tableBodyCell}>{item?.id}</TableCell>
                  <TableCell className={classes.tableBodyCell}>{item?.tenant_name}</TableCell>
                  <TableCell className={classes.tableBodyCell}>{item?.floor}</TableCell>
                  <TableCell className={classes.tableBodyCell}>{item?.suite}</TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item?.area_leased}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {formatDate(item?.commencement_date)}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {formatDate(item?.expiry_date)}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item?.rent}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    <IconButton size="small" onClick={() => handleEditClick(item)}>
                      <ModeEditOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>

              ))}



            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="p" className={classes.tableTitle}>
            Legal Documents
          </Typography>
          <Button variant="contained" sx={{ color: "#fff" }} onClick={handleOpenDocModal}>
            attach document 
          </Button>
        </Stack>
        <Stack gap={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ borderRadius: "4px", background: " #0F333D" }}
            p={2}
          >
            <Typography variant="p" className={classes.docTitle}>
              Document Name
            </Typography>
            <Typography variant="p" className={classes.docTitle}>
              Actions
            </Typography>
          </Stack>
          {tenantDocs.map((item) => (
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ borderRadius: "4px", background: " #F6F7FB" }}
              p={2}
              key={item.id}
            >
              <Typography variant="p" className={classes.docTable}>
                {item.doc_name}
              </Typography>
              <Typography variant="p" className={classes.docTitle}>
                <Stack direction="row" gap={2}>
                  <IconButton size="small" onClick={() => handleDeleteModalOpen(item.id)}>
                    <Delete sx={{ color: "#82959C" }} />
                  </IconButton>
                  <Link
                   to={config.BUCKET_URL+item.doc_path}
                   target="_blank"
                   download
                   
                   >
                  <IconButton size="small" >
                    <Visibility sx={{ color: "#82959C" }} />
                  </IconButton>
                  </Link>
                </Stack>
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      {dialog && (
        <DeleteModal open={dialog} handelClose={handleDeleteModalClose} label={"Are you sure you want to delete this Document ?"} description={"By pressing this delete button will remove this Document permanently."} id={selectedDoc} deleteRow={handleDelete} />
      )}
      {openUpdateModal && selectedTenant && (
        <TenantModal open={openUpdateModal} handleClose={handleCloseUpdateModal} tenantData={selectedTenant} handleUpdate={handleUpdate} />
      )}
      {documentModal && (
        <AddDocsModal open={documentModal} handleClose={handleCloseDocModel} handleDocSubmit={handleDOcSubmit} />
      )}
    </Stack>
  );
};

export default ViewTenants;
