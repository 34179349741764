/** @format */

import { ArrowBack, Visibility } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,

} from "@mui/material";
import React, { useEffect, useState , useCallback} from "react";
import DeleteModal from "../../modals/DeleteModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "../../pages/dataVault/dataVault.module.css";
import classes from "./assetManagement.module.css";
import AssetNavBar from "../UI/AssetNavBar";
import theme from "../theme";
import { successToast } from "../../utils/alerts";
import { addTenant, deleteTenant, getTenantList } from "../../services/private/assetManagement/assetServices";
import TenantModal from "../../modals/TenantModal";
// import { useSelector } from "react-redux";

// const tableData = [
//   {
//     id: 1,
//     col1: 20796,
//     col2: "Robert Fox",
//     col3: 1,
//     col4: 536,
//     col5: "5560 sqFt",
//     col6: "8 Sep, 2020",
//     col7: "1 Feb, 2020",
//     col8: "$3,443.00",
//   },
//   {
//     id: 2,
//     col1: 20796,
//     col2: "Robert Fox",
//     col3: 1,
//     col4: 536,
//     col5: "5560 sqFt",
//     col6: "8 Sep, 2020",
//     col7: "1 Feb, 2020",
//     col8: "$3,443.00",
//   },
//   {
//     id: 3,
//     col1: 20796,
//     col2: "Robert Fox",
//     col3: 1,
//     col4: 536,
//     col5: "5560 sqFt",
//     col6: "8 Sep, 2020",
//     col7: "1 Feb, 2020",
//     col8: "$3,443.00",
//   },
//   {
//     id: 4,
//     col1: 20796,
//     col2: "Robert Fox",
//     col3: 1,
//     col4: 536,
//     col5: "5560 sqFt",
//     col6: "8 Sep, 2020",
//     col7: "1 Feb, 2020",
//     col8: "$3,443.00",
//   },
//   {
//     id: 5,
//     col1: 20796,
//     col2: "Robert Fox",
//     col3: 1,
//     col4: 536,
//     col5: "5560 sqFt",
//     col6: "8 Sep, 2020",
//     col7: "1 Feb, 2020",
//     col8: "$3,443.00",
//   },
//   {
//     id: 6,
//     col1: 20796,
//     col2: "Robert Fox",
//     col3: 1,
//     col4: 536,
//     col5: "5560 sqFt",
//     col6: "8 Sep, 2020",
//     col7: "1 Feb, 2020",
//     col8: "$3,443.00",
//   },
//   {
//     id: 7,
//     col1: 20796,
//     col2: "Robert Fox",
//     col3: 1,
//     col4: 536,
//     col5: "5560 sqFt",
//     col6: "8 Sep, 2020",
//     col7: "1 Feb, 2020",
//     col8: "$3,443.00",
//   },
//   {
//     id: 8,
//     col1: 20796,
//     col2: "Robert Fox",
//     col3: 1,
//     col4: 536,
//     col5: "5560 sqFt",
//     col6: "8 Sep, 2020",
//     col7: "1 Feb, 2020",
//     col8: "$3,443.00",
//   },
// ];

const ViewAssets = () => {
  const navigate = useNavigate();
  const id = useParams()
  const location = useLocation()
  const { name } = location.state || {}
  const [tenantData, setTenantData] = useState([])
  const [selectedTenantId, setSelectedTenantId] = useState([]);
  const [dialog, setDialog] = useState(false)
  const [addModal, setAddModal] = useState(false)
  
  const serverRequest = useCallback(async () => {
    const entities = await getTenantList(id.assetId);
    setTenantData(entities.data);
  }, [id.assetId]);
  
  useEffect(() => {
  serverRequest()
  }, [serverRequest, id.assetId])
  const handleDeleteModalOpen = async() => {
    setDialog(true)
  }
  const handleDeleteModalClose = async () => {
    setDialog(false)
  }
  const handleAddModalOpen = async() => {
    setAddModal(true)
  }
  const handleAddModalClose = async() => {
    setAddModal(false)
  }

  const handleCheckboxChange = (id) => {
    setSelectedTenantId((prevSelected) => {
      if (prevSelected.includes(id)) {
        // If ID is already selected, remove it
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        // If ID is not selected, add it to the array
        return [...prevSelected, id];
      }
    });
  };
  const handleDeleteTenant = async ()=> {
    // console.log("Delete these tenants:", selectedTenantId);
    
    await deleteTenant(selectedTenantId)
    setDialog(false); 
    successToast("Tenant deleted Successfully")
    setSelectedTenantId([]); 
    serverRequest()
  }
  const handleAddTenant = async(payload) => {
    try {
      await addTenant(payload, id.assetId)
      successToast("Tenant added successfully")
      setAddModal(false)
      serverRequest()
    } catch (error) {
      successToast(error.message)
    }

  }
  
  function formatDate(dateString) {
    const date = new Date(dateString)
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for the day
    const month = months[date.getMonth()]; // Get the month abbreviation
    const year = date.getFullYear(); // Get the full year

    return `${day} ${month} ${year}`;
  }

  return (
    <Stack className={styles.dataVaultContainer}>
      <AssetNavBar />
      <Stack p={3} gap={3} className={classes.tenantContainer}>
        <Stack direction="row" gap={2}>
          <IconButton
            size="small"
            className={styles.backArrow}
            onClick={() => navigate("/asset-management")}
          >
            <ArrowBack />
          </IconButton>
          {name  && (
            <Typography variant="h6" className={classes.pagetitle}>
              {name}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <TextField
            variant="outlined"
            size="small"
            label="Search"
            placeholder="Name, email, etc..."
          />
          <Stack direction="row" spacing={2}>
            {selectedTenantId.length > 0 && (
            <Button variant="outlined" startIcon={<DeleteIcon />} onClick={() => handleDeleteModalOpen()}>
              Delete
            </Button>

            )}
            <Button variant="contained" sx={{ color: "#fff" }} onClick={()=> handleAddModalOpen()}>
              Add New Tenant
            </Button>
          </Stack>
        </Stack>
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes.container}
        >
          <Table>
            <TableHead>
              <TableRow
                className={classes.tableHeadRow}
                sx={{ bgcolor: theme.palette.esgblueblack.main }}
              >
                <TableCell className={classes.tableHeadCell}></TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Tenant ID
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Tenant name
                </TableCell>
                <TableCell className={classes.tableHeadCell}>Floor</TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Suite No.
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Area Leased
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Commencement Date
                </TableCell>
                <TableCell className={classes.tableHeadCell}>
                  Expiry Date
                </TableCell>
                <TableCell className={classes.tableHeadCell}>Rent</TableCell>
                <TableCell className={classes.tableHeadCell}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tenantData.map((item) => (
                <TableRow className={classes.tableBodyRow} key={item.tenants.id}>
                  <TableCell className={classes.tableBodyCell}>
                    <Checkbox
                      size="small"
                      checked={selectedTenantId.includes(item.tenants.id)}
                      onChange={() => handleCheckboxChange(item.tenants.id)} // Handle checkbox change
                    />
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item.tenants.id}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item.tenants.tenant_name}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item.tenants.floor}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item.tenants.suite}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item.tenants.area_leased} sqft
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {formatDate(item.tenants.commencement_date)}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {formatDate(item.tenants.expiry_date)}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    {item.tenants.rent}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>
                    <IconButton
                      size="small"
                      onClick={() => navigate(`view-tenants/${item.tenants.id}`, {
                        state: {
                          tenant: item.tenants,
                          tenantDocs: item.tenantDocs
                        } 
                      })}
                    >
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {dialog && (
         <DeleteModal
         open={dialog}
         handelClose={handleDeleteModalClose}
         label={"Are you sure you want to delete the selected tenant(s) ?"}
         description={
           "By pressing this delete button will remove the selected tenants."
         }
         id={selectedTenantId}
         deleteRow={handleDeleteTenant} 
       />
      )}
      {addModal && (
        <TenantModal open = {addModal} handleClose = {handleAddModalClose} handleAddTenant={handleAddTenant}/>
      )}
    </Stack>
  );
};

export default ViewAssets;
