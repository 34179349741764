/** @format */

import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BarChart from '../components/charts/BarChart';
import DoughnutChart from '../components/charts/Doughnut';
import { useSelector } from 'react-redux';
import {
  wasteBarChart,
  wasteDoughnutChart,
} from '../services/private/waste/WasteService';

const backgroundColors = ['#A155B9', '#165BAA', '#F765A3', '#16BFD6'];
const WasteAnalytics = () => {
  const [barData, setBarData] = useState([]);
  const [doughnutData, setDoughnutData] = useState([]);
  const chartData = {
    labels: barData.map((data) => data.year),
    datasets: [
      {
        label: 'Waste Consumption',
        type: 'line',
        data: barData.map((data) => data.sum),
        borderColor: '#6DB3B5',
        fill: true,
        tension: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      {
        label: 'Waste Consumption',
        type: 'bar',
        data: barData.map((data) => data.sum),
        backgroundColor: '#165BAA',
        barThickness: 32,
      },
    ],
  };
  const pieData1 = {
    labels: doughnutData.map((data) => data.location),
    datasets: [
      {
        label: 'Waste Consumption',
        type: 'doughnut',
        data: doughnutData.map((data) => data.percentage),
        backgroundColor: backgroundColors,
        borderWidth: 0,
      },
    ],
  };
  const pieData2 = {
    labels: doughnutData.map((data) => data.type),
    datasets: [
      {
        label: 'Waste Consumption',
        type: 'doughnut',
        data: doughnutData.map((data) => data.percentage),
        backgroundColor: backgroundColors,
        borderWidth: 0,
      },
    ],
  };

  const { fy_start, fy_end } = useSelector((state) => state.years.selectedYear);

  async function fetchDoughnutChartData(fyStart, fyEnd) {
    try {
      const res = await wasteDoughnutChart(fyStart, fyEnd);
      setDoughnutData(res.data);
    } catch (err) {
      // console.log(err);
    }
  }
  async function fetchBarChartData() {
    try {
      const res = await wasteBarChart();
      setBarData(res.data);
    } catch (err) {
      // console.log(err);
    }
  }
  useEffect(() => {
    fetchDoughnutChartData(fy_start, fy_end);
  }, [fy_start, fy_end]);

  useEffect(() => {
    fetchBarChartData();
  }, []);

  const updatedDoughnutData1 = doughnutData.map((data, index) => ({
    location: data.location,
    location_id: data.location_id,
    percentage: data.percentage,
    sum: data.sum,
    backgroundColor: backgroundColors[index],
  }));
  const updatedDoughnutData2 = doughnutData.map((data, index) => ({
    location: data.type,
    location_id: data.location_id,
    percentage: data.percentage,
    sum: data.sum,
    backgroundColor: backgroundColors[index],
  }));

  return (
    <>
      <Stack gap="24px">
        <BarChart
          chartData={chartData}
          yAxisTitle="Waste Consumption [m3]"
          chartTitle="Yearly Waste Consumption"
          aspectRatio={5}
        />
      </Stack>
      <Stack direction="row" gap="24px">
        <DoughnutChart
          chartData={pieData1}
          labelsData={updatedDoughnutData1}
          type="Total Waste Consumption"
          chartHeight={300}
        />
        <DoughnutChart
          chartData={pieData2}
          labelsData={updatedDoughnutData2}
          type="Total Waste Type"
          chartHeight={300}
        />
      </Stack>
    </>
  );
};

export default WasteAnalytics;
