/** @format */

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { shareDataVault } from '../services/private/dataVault/dataVaultService';
import { errorToast, successToast } from '../utils/alerts';
const ShareDataVaultModal = ({setOpen}) => {
  const [activeStep, setActiveStep] = useState(1);
  const [email, setEmail] = useState('');
  const [governance, setGovernance] = useState(false);
  const [reporting, setReporting] = useState(false);
  const [subOptions, setSubOptions] = useState({
    leadership: false,
    decision: false,
    corporate: false,
    sdg: false,
    tcfd: false,
  });
  const [error, setError] = useState({
    status: false, 
    message: '',
  })

  const handleNext = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setError({
        status: true,
        message: 'Please enter a valid email address.',
      });
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError({
      status: false,
      message:''
    })
  };

  const handleMainOptionChange = (option) => {
    if (option === 'governance') {
      setGovernance(!governance);
      setSubOptions({
        ...subOptions,
        leadership: false,
        decision: false,
        corporate: false,
      });
    } else if (option === 'reporting') {
      setReporting(!reporting);
      setSubOptions({
        ...subOptions,
        sdg: false,
        tcfd: false,
      });
    }
  };

  const handleSubOptionChange = (option) => {
    setSubOptions({
      ...subOptions,
      [option]: !subOptions[option],
    });
    setError({
      status: false,
      message: '',
    });
  };
  const handleSubmit = async () => {
    // Build query parameters based on selected options
    if (governance && !['leadership', 'decision', 'corporate'].some((module) => subOptions[module])) {
      setError((prevState)=> ({...prevState, status: true, message: 'Please select at least one sub-option for governance'}));
      return;
    }
  
    if (reporting && !['sdg', 'tcfd'].some((module) => subOptions[module])) {
      setError((prevState)=> ({...prevState, status: true, message: 'Please select at least one sub-option for reporting'}));
      return;
    }
    
    let query = '';
    
    if (governance) {
      const governanceModules = ['leadership', 'decision', 'corporate']
        .filter((module) => subOptions[module])
        .join(',');
      if (governanceModules) {
        query += `tab[governance]=${governanceModules}&`;
      }
    }
  
    if (reporting) {
      const reportingModules = ['sdg', 'tcfd']
        .filter((module) => subOptions[module])
        .join(',');
      if (reportingModules) {
        query += `tab[reporting]=${reportingModules}&`;
      }
    }
  
    // Trim trailing ampersand (&) from query string
    query = query.slice(0, -1);
  
    // Payload to send
    const payload = {
      email: email,
    };
    try {
      const res = await shareDataVault(payload, query)
      if(res.type === 'RXSUCCESS'){
        setError((prevState)=> ({...prevState, status: false, message: ''}));
        setOpen(false)
        successToast(res.message)
      }
    } catch (error) {
      errorToast(error.message)
    }
  
    // Call service function with query and payload
   
  };
  

  return (
    <Box sx={{ width: '100%', maxWidth: 650, margin: 'auto', mt: 5 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step>
          <StepLabel>Enter Email</StepLabel>
        </Step>
        <Step>
          <StepLabel>Select Options</StepLabel>
        </Step>
      </Stepper>
      <Box sx={{ mt: 3 }}>
        {activeStep === 1 ? (
          <Stack spacing={2}>
            <Typography variant="h6">Enter your email</Typography>
            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              fullWidth
            />
            {error.status&& (
              <span style={{color:"red"}}>{error.message}</span>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                // onClick={() => setActiveStep(2)}
                onClick={handleNext}
                disabled={!email}
                sx={{ color: '#fff' }}
              >
                Next
              </Button>
            </Box>
          </Stack>
        ) : (
          <Stack spacing={2}>
            <Typography variant="h6">Select Options</Typography>

            {/* Sub-options side by side */}
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={governance}
                        onChange={() => handleMainOptionChange('governance')}
                      />
                    }
                    label="Governance"
                  />
                  <Stack pl={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={subOptions.leadership}
                          onChange={() => handleSubOptionChange('leadership')}
                          disabled={!governance}
                        />
                      }
                      label="Leadership Commitment"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={subOptions.decision}
                          onChange={() => handleSubOptionChange('decision')}
                          disabled={!governance}
                        />
                      }
                      label="ESG Decision Making"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={subOptions.corporate}
                          onChange={() => handleSubOptionChange('corporate')}
                          disabled={!governance}
                        />
                      }
                      label="Corporate ESG Reporting"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={reporting}
                        onChange={() => handleMainOptionChange('reporting')}
                      />
                    }
                    label="ESG Reporting"
                  />
                  <Stack pl={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={subOptions.sdg}
                          onChange={() => handleSubOptionChange('sdg')}
                          disabled={!reporting}
                        />
                      }
                      label="Sustainable Development Goals"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={subOptions.tcfd}
                          onChange={() => handleSubOptionChange('tcfd')}
                          disabled={!reporting}
                        />
                      }
                      label="TCFD"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </FormGroup>
            {error.status&& (
              <span style={{color:"red"}}>{error.message}</span>
            )}

            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Button variant="outlined" onClick={() => setActiveStep(1)}>
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={!governance && !reporting}
                sx={{ color: '#fff' }}
                
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default ShareDataVaultModal;
