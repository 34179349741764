/** @format */

import React, { useEffect, useState } from 'react';
import HorizontalBarChart from '../charts/HorizontalBarChart';
import BarChart from '../charts/BarChart';
import { Box, Stack } from '@mui/material';
import DoughnutChart from '../charts/Doughnut';
import { targetBarChart } from '../../services/private/targets/TargetService';
import { useSelector } from 'react-redux';
import {
  energyBarChart,
  energyDoughnutChart,
} from '../../services/private/energy/EnergyService';
import {
  waterBarChart,
  waterDoughnutChart,
} from '../../services/private/water/WaterService';
import {
  wasteBarChart,
  wasteDoughnutChart,
} from '../../services/private/waste/WasteService';

const backgroundColors = ['#A155B9', '#165BAA', '#F765A3', '#16BFD6'];
const ReportingAnalytics = () => {
  const { fy_start, fy_end } = useSelector((state) => state.years.selectedYear);
  const [targetBarData, setTargetBarData] = useState([]);
  const [energyBarData, setEnergyBarData] = useState([]);
  const [energyDoughnutData, setEnergyDoughnutData] = useState([]);
  const [waterBarData, setWaterBarData] = useState([]);
  const [waterDoughnutData, setWaterDoughnutData] = useState([]);
  const [wasteDoughnutData, setWasteDoughnutData] = useState([]);
  const [wasteBarData, setWasteBarData] = useState([]);
  const wasteChartData = {
    labels: wasteBarData.map((data) => data.year),
    datasets: [
      {
        label: 'Waste Consumption',
        type: 'line',
        data: wasteBarData.map((data) => data.sum),
        borderColor: '#6DB3B5',
        fill: true,
        tension: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      {
        label: 'Waste Consumption',
        type: 'bar',
        data: wasteBarData.map((data) => data.sum),
        backgroundColor: '#165BAA',
        barThickness: 32,
      },
    ],
  };
  const pieData1 = {
    labels: wasteDoughnutData.map((data) => data.location),
    datasets: [
      {
        label: 'Waste Consumption',
        type: 'doughnut',
        data: wasteDoughnutData.map((data) => data.percentage),
        backgroundColor: backgroundColors,
        borderWidth: 0,
      },
    ],
  };
  const pieData2 = {
    labels: wasteDoughnutData.map((data) => data.type),
    datasets: [
      {
        label: 'Waste Consumption',
        type: 'doughnut',
        data: wasteDoughnutData.map((data) => data.percentage),
        backgroundColor: backgroundColors,
        borderWidth: 0,
      },
    ],
  };
  const waterChartData = {
    labels: waterBarData.map((data) => data.year),
    datasets: [
      {
        label: 'Water Consumption',
        type: 'line',
        data: waterBarData.map((data) => data.sum),
        borderColor: '#6DB3B5',
        fill: true,
        tension: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      {
        label: 'Water Consumption',
        type: 'bar',
        data: waterBarData.map((data) => data.sum),
        backgroundColor: '#165BAA',
        barThickness: 32,
      },
    ],
  };
  const waterPieData = {
    labels: waterDoughnutData.map((data) => data.location),
    datasets: [
      {
        label: 'Water Consumption',
        type: 'doughnut',
        data: waterDoughnutData.map((data) => data.percentage),
        backgroundColor: backgroundColors,
        borderWidth: 0,
      },
    ],
  };
  const targetChartData = {
    labels: targetBarData.map((data) => data.year),
    datasets: [
      {
        label: 'Emissions Reduction',
        type: 'line',
        data: targetBarData.map((data) => data.sum || data.totalEmission),
        borderColor: '#6DB3B5',
        fill: true,
        tension: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      {
        label: 'Emissions Reduction',
        type: 'bar',
        data: targetBarData.map((data) => data.sum || data.totalEmission),
        backgroundColor: '#165BAA',
        barThickness: 32,
      },
    ],
  };

  const energyChartData = {
    labels: energyBarData.map((data) => data.year),
    datasets: [
      {
        label: 'Energy Consumption',
        type: 'line',
        data: energyBarData.map((data) => data.sum),
        borderColor: '#6DB3B5',
        fill: true,
        tension: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      {
        label: 'Energy Consumption',
        type: 'bar',
        data: energyBarData.map((data) => data.sum),
        backgroundColor: '#165BAA',
        barThickness: 32,
      },
    ],
  };

  const energyPieData = {
    labels: energyDoughnutData.map((data) => data.location),
    datasets: [
      {
        label: 'Energy Consumption',
        type: 'doughnut',
        data: energyDoughnutData.map((data) => data.percentage),
        backgroundColor: backgroundColors,
        borderWidth: 0,
      },
    ],
  };

  async function fetchDoughnutChartData(fyStart, fyEnd) {
    try {
      const resEnergy = await energyDoughnutChart(fyStart, fyEnd);
      const resWater = await waterDoughnutChart(fyStart, fyEnd);
      const res = await wasteDoughnutChart(fyStart, fyEnd);
      setWasteDoughnutData(res.data);
      setWaterDoughnutData(resWater.data);

      setEnergyDoughnutData(resEnergy.data);
    } catch (err) {
      // console.log(err);
    }
  }

  async function fetchBarChartData() {
    try {
      const resTarget = await targetBarChart();
      const resEnergy = await energyBarChart();
      const resWater = await waterBarChart();
      const res = await wasteBarChart();
      setWasteBarData(res.data);
      setWaterBarData(resWater.data);
      setEnergyBarData(resEnergy.data);
      setTargetBarData(resTarget.data);
    } catch (err) {
      // console.log(err);
    }
  }

  useEffect(() => {
    fetchBarChartData();
  }, []);

  useEffect(() => {
    fetchDoughnutChartData(fy_start, fy_end);
  }, [fy_start, fy_end]);

  const updatedDoughnutData1 = wasteDoughnutData.map((data, index) => ({
    location: data.location,
    location_id: data.location_id,
    percentage: data.percentage,
    sum: data.sum,
    backgroundColor: backgroundColors[index],
  }));
  const updatedDoughnutData2 = wasteDoughnutData.map((data, index) => ({
    location: data.type,
    location_id: data.location_id,
    percentage: data.percentage,
    sum: data.sum,
    backgroundColor: backgroundColors[index],
  }));
  return (
    <Box pt={3}>
      <HorizontalBarChart />
      <BarChart
        chartData={targetChartData}
        yAxisTitle="C0-2-Emissions [tC02e]"
        chartTitle="Emissions Reduction Targets.(C02E)"
        aspectRatio={5}
      />
      <Stack direction="row" gap="24px">
        <BarChart
          chartData={energyChartData}
          yAxisTitle="Energy Consumption [MWhs]"
          chartTitle="Yearly Energy Consumption"
          aspectRatio={3}
        />
        <DoughnutChart
          chartData={energyPieData}
          type="Total Energ Consumptiony"
          chartHeight={250}
        />
      </Stack>
      <Stack direction="row" gap="24px">
        <BarChart
          chartData={waterChartData}
          yAxisTitle="Water Consumption [m3]"
          chartTitle="Yearly Water Consumption"
          aspectRatio={3}
        />
        <DoughnutChart
          chartData={waterPieData}
          type="Total Water Consumption"
          chartHeight={250}
        />
      </Stack>

      <BarChart
        chartData={wasteChartData}
        yAxisTitle="Waste Consumption [m3]"
        chartTitle="Yearly Waste Consumption"
        aspectRatio={5}
      />

      <Stack direction="row" spacing={3} sx={{ width: '100%' }}>
        <div
          style={{
            flexGrow: 1,
            borderRadius: '6px',
            backgroundColor: '#f6f7fb',
          }}
        >
          <DoughnutChart
            chartData={pieData1}
            labelsData={updatedDoughnutData1}
            type="Total Waste Consumption"
            chartHeight={300}
          />
        </div>
        <div
          style={{
            flexGrow: 1,
            borderRadius: '6px',
            backgroundColor: '#f6f7fb',
          }}
        >
          <DoughnutChart
            chartData={pieData2}
            labelsData={updatedDoughnutData2}
            type="Total Waste Type"
            chartHeight={300}
          />
        </div>
      </Stack>
    </Box>
  );
};

export default ReportingAnalytics;
