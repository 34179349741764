/** @format */

import React, { useState, useEffect } from 'react';
import { Box, Typography, Stack, Button, Pagination } from '@mui/material';
import TabComponent from '../../components/UI/TabComponent';
import FooterCard from '../../components/UI/FooterCard';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import MonthRecordCard from '../../components/UI/MonthRecordCard';
import styles from './waste.module.css';
import {
  requestWasteConsumption,
  deleteWasteConsumption,
} from '../../services/private/waste/WasteService';
import DeleteModal from '../../modals/DeleteModal';
import WasteAnalytics from '../../components/WasteAnalytics';
import { successToast, errorToast } from '../../utils/alerts';
import { useSelector } from 'react-redux';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant="p">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Waste = () => {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);
  const Limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [entities, setEntities] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [pagination, setPagination] = useState({
    totalRows: 0,
    currentPage: 1,
  });
  const selectedYear = useSelector((state) => state.years.selectedYear);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const serverRequest = async (selectedYear) => {
    try {
      const response = await requestWasteConsumption(
        selectedYear.fy_start,
        selectedYear.fy_end,
        Limit,
        currentPage
      );
      setEntities(response.data.rows);
      setPagination(response.data.pagination);
    } catch (error) {
      errorToast(error.message);
    }
  };

  const openDeleteModal = async (id) => {
    setSelectedItem(id);
    setDeleteModal(true);
  };

  const handelDeleteRow = async (id) => {
    try {
      await deleteWasteConsumption(id);
      setDeleteModal(false);
      setSelectedItem(null);
      successToast('Deleted Successfully');
      await serverRequest(selectedYear);
    } catch (error) {
      setDeleteModal(false);
      errorToast(error.message);
    }
  };

  const handleUpdateMenu = async (id) => {
    setSelectedItem(id);
    navigate(`/waste/waste-consumption-details/${id}`);
  };

  const calculateType = (type) => {
    let entity = null;
    switch (type) {
      case '0':
        entity = 'Recycled';
        break;
      case '1':
        entity = 'Disposal';
        break;
      case '2':
        entity = 'Not Recycled';
        break;
      case '3':
        entity = 'Scrap';
        break;
      default:
        entity = undefined;
        break;
    }

    return entity;
  };

  useEffect(() => {
    serverRequest(selectedYear);
    // eslint-disable-next-line
  }, [currentPage, selectedYear]);

  return (
    <>
      <TabComponent
        tab1Name={' SUMMARY'}
        tab1Width={'230px'}
        tab2Name={'ANALYTICS'}
        tab2Width={'348px'}
        value={value}
        handleChange={handleChange}
      />
      <CustomTabPanel value={value} index={0} className="target-list-tab-width">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="p" className="page-headline">
            Waste Consumption Details
          </Typography>
          <Button
            variant="contained"
            sx={{ color: '#fff' }}
            onClick={() => navigate('add-waste-details')}
          >
            Add New Asset
          </Button>
        </Stack>
        {entities.map((entity, index) => (
          <MonthRecordCard
            key={index}
            entity={entity}
            label={`${calculateType(entity?.type)} • ${entity?.unit}`}
            deleteRow={openDeleteModal}
            handelUpdate={handleUpdateMenu}
            addRoute={`/waste/view-waste-consumption/${entity?.id}`}
          />
        ))}
        <Box sx={{ margin: '24px 0' }} className={styles.wastePagination}>
          <Pagination
            count={Math.ceil(pagination.totalRows / Limit)}
            showFirstButton
            showLastButton
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>

        {deleteModal && (
          <DeleteModal
            open={deleteModal}
            handelClose={() => {
              setDeleteModal(false);
            }}
            label={'Are you sure you want to delete this?'}
            description={
              'By clicking delete button will permanently delete record'
            }
            deleteRow={handelDeleteRow}
            id={selectedItem}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <WasteAnalytics />
      </CustomTabPanel>
      <FooterCard />
    </>
  );
};

export default Waste;
