/** @format */
import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import './App.css';
import React from 'react';
import Layout from './components/Layout';
import { ThemeProvider } from '@emotion/react';
import theme from './components/theme';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import Dashboard from './pages/Dashboard';
import Energy from './pages/energy/Energy';
import Scope1 from './pages/scope1/Scope1';
import Scope2 from './pages/scope2/Scope2';
import Scope3 from './pages/scope3/Scope3';
import Water from './pages/water/Water';
import Waste from './pages/waste/Waste';
// import DataVault from "./pages/dataVault/DataVault";
import AssetManagement from './pages/assetManagement/AssetManagement';
import AddNewTargets from './pages/targets/AddNewTargets';
import Targets from './pages/targets/Targets';
import Reporting from './pages/Reporting';
import Settings from './pages/Settings';
import Login from './components/Login';
import { Toaster } from 'react-hot-toast';
import { getStore } from './utils/UniversalFunction';
import Unauthorised from './pages/Unauthorised';
import { PersistGate } from 'redux-persist/integration/react';
import UpdateTarget from './pages/targets/UpdateTarget';
import AddEnergyDetails from './pages/energy/AddEnergyDetails';
import AddWaterDetails from './pages/water/AddWaterDetails';
import ViewWaterConsumption from './pages/water/ViewWaterConsumption';
import AddWasteDetails from './pages/waste/AddWasteDetails';
import ViewWasteConsumption from './pages/waste/ViewWasteConsumption';
import AddScope1Details from './pages/scope1/AddScope1Details';
import AddScope2Details from './pages/scope2/AddScope2Details';
import AddScope3Details from './pages/scope3/AddScope3Details';
import CalculateScope2Details from './components/scope2/CalculateScope2Details';
import UpdateEnergyDetails from './pages/energy/UpdateEnergyDetails';
import UpdateWaterDetails from './pages/water/UpdateWaterDetails';
import UpdateWasteDetails from './pages/waste/UpdateWasteDetails';
import UpdateScope3 from './pages/scope3/UpdateScope3';
import UpdateScope1 from './pages/scope1/UpdateScope1';
import UpdateScope2 from './pages/scope2/UpdateScope2';
import LeadershipCommitments from './pages/dataVault/governance/LeadershipCommitments';
import DecisionMaking from './pages/dataVault/governance/DecisionMaking';
import CorporateReporting from './pages/dataVault/governance/CorporateReporting';
import Sdgs from './pages/dataVault/esgReporting/Sdgs';
import Tcfd from './pages/dataVault/esgReporting/Tcfd';
import ReportingIntelligence from './pages/dataVault/esgReporting/ReportingIntelligence';
import SdgQuestionnaire from './pages/dataVault/esgReporting/SdgQuestionnaire';
import AddNewAsset from './pages/assetManagement/AddNewAsset';
import ViewTenants from './components/assetManagement/ViewTenants';
import ViewAssets from './components/assetManagement/ViewAssets';

const PrivateRoute = () => {
  const auth = getStore('user');
  return auth ? <Outlet /> : <Unauthorised />;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <Router> */}
          <Layout>
            <Toaster />
            <Routes>
              <Route path="/login/:token" element={<Login />} />
              <Route path="/unauthorised" element={<Unauthorised />} />

              <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<Dashboard />} />
              </Route>

              <Route path="/targets" element={<PrivateRoute />}>
                <Route path="/targets" element={<Targets />} />
              </Route>

              <Route path="/targets/add-new-target" element={<PrivateRoute />}>
                <Route
                  path="/targets/add-new-target"
                  element={<AddNewTargets />}
                />
              </Route>

              <Route
                path="/targets/update-target/:id"
                element={<PrivateRoute />}
              >
                <Route
                  path="/targets/update-target/:id"
                  element={<UpdateTarget />}
                />
              </Route>

              <Route path="/energy" element={<PrivateRoute />}>
                <Route path="/energy" element={<Energy />} />
              </Route>

              <Route
                path="/energy/add-energy-details"
                element={<PrivateRoute />}
              >
                <Route
                  path="/energy/add-energy-details"
                  element={<AddEnergyDetails />}
                />
              </Route>

              <Route
                path="/energy/update-energy-details/:id"
                element={<PrivateRoute />}
              >
                <Route
                  path="/energy/update-energy-details/:id"
                  element={<UpdateEnergyDetails />}
                />
              </Route>

              {/* Scope1---------------- */}
              <Route path="/scope1" element={<PrivateRoute />}>
                <Route path="/scope1" element={<Scope1 />} />
              </Route>
              <Route
                path="/scope1/add-scope-details"
                element={<PrivateRoute />}
              >
                <Route
                  path="/scope1/add-scope-details"
                  element={<AddScope1Details />}
                />
              </Route>
              <Route
                path="/scope/update-scope1-details/:id"
                element={<PrivateRoute />}
              >
                <Route
                  path="/scope/update-scope1-details/:id"
                  element={<UpdateScope1 />}
                />
              </Route>

              {/* Scope2---------------- */}
              <Route path="/scope2" element={<PrivateRoute />}>
                <Route path="/scope2" element={<Scope2 />} />
              </Route>
              <Route
                path="/scope2/add-scope-details"
                element={<PrivateRoute />}
              >
                <Route
                  path="/scope2/add-scope-details"
                  element={<AddScope2Details />}
                />
              </Route>
              <Route
                path="/scope2/add-scope-details/add"
                element={<PrivateRoute />}
              >
                <Route
                  path="/scope2/add-scope-details/add"
                  element={<CalculateScope2Details />}
                />
              </Route>

              <Route
                path="/scope/update-scope2-details/:id"
                element={<PrivateRoute />}
              >
                <Route
                  path="/scope/update-scope2-details/:id"
                  element={<UpdateScope2 />}
                />
              </Route>

              {/* Scope3---------------- */}
              <Route path="/scope3" element={<PrivateRoute />}>
                <Route path="/scope3" element={<Scope3 />} />
              </Route>
              <Route
                path="/scope3/add-scope-details"
                element={<PrivateRoute />}
              >
                <Route
                  path="/scope3/add-scope-details"
                  element={<AddScope3Details />}
                />
              </Route>

              <Route
                path="/scope/update-scope3-details/:id"
                element={<PrivateRoute />}
              >
                <Route
                  path="/scope/update-scope3-details/:id"
                  element={<UpdateScope3 />}
                />
              </Route>

              <Route path="/water" element={<PrivateRoute />}>
                <Route path="/water" element={<Water />} />
              </Route>

              <Route path="/water/add-water-details" element={<PrivateRoute />}>
                <Route
                  path="/water/add-water-details"
                  element={<AddWaterDetails />}
                />
              </Route>

              <Route
                path="/water/view-water-consumption/:id"
                element={<PrivateRoute />}
              >
                <Route
                  path="/water/view-water-consumption/:id"
                  element={<ViewWaterConsumption />}
                />
              </Route>

              <Route
                path="/water/water-consumption-details/:id"
                element={<PrivateRoute />}
              >
                <Route
                  path="/water/water-consumption-details/:id"
                  element={<UpdateWaterDetails />}
                />
              </Route>

              <Route path="/waste" element={<PrivateRoute />}>
                <Route path="/waste" element={<Waste />} />
              </Route>

              <Route path="/waste/add-waste-details" element={<PrivateRoute />}>
                <Route
                  path="/waste/add-waste-details"
                  element={<AddWasteDetails />}
                />
              </Route>
              <Route
                path="/waste/view-waste-consumption/:id"
                element={<PrivateRoute />}
              >
                <Route
                  path="/waste/view-waste-consumption/:id"
                  element={<ViewWasteConsumption />}
                />
              </Route>

              <Route
                path="/waste/waste-consumption-details/:id"
                element={<PrivateRoute />}
              >
                <Route
                  path="/waste/waste-consumption-details/:id"
                  element={<UpdateWasteDetails />}
                />
              </Route>

              <Route path="/data-vault" element={<PrivateRoute />}>
                <Route
                  path="/data-vault"
                  element={<Navigate to="/data-vault/leadership-commitments" />}
                />
              </Route>
              <Route
                path="/data-vault/leadership-commitments"
                element={<PrivateRoute />}
              >
                <Route
                  path="/data-vault/leadership-commitments"
                  element={<LeadershipCommitments />}
                />
              </Route>
              <Route
                path="/data-vault/decision-making"
                element={<PrivateRoute />}
              >
                <Route
                  path="/data-vault/decision-making"
                  element={<DecisionMaking />}
                />
              </Route>
              <Route
                path="/data-vault/corporate-reporting"
                element={<PrivateRoute />}
              >
                <Route
                  path="/data-vault/corporate-reporting"
                  element={<CorporateReporting />}
                />
              </Route>
              <Route path="/data-vault/sdg" element={<PrivateRoute />}>
                <Route path="/data-vault/sdg" element={<Sdgs />} />
              </Route>

              <Route path="/data-vault/sdg/:id" element={<PrivateRoute />}>
                <Route
                  path="/data-vault/sdg/:id"
                  element={<SdgQuestionnaire />}
                />
              </Route>
              <Route path="/data-vault/tcfd" element={<PrivateRoute />}>
                <Route path="/data-vault/tcfd" element={<Tcfd />} />
              </Route>
              <Route
                path="/data-vault/reporting-intelligence"
                element={<PrivateRoute />}
              >
                <Route
                  path="/data-vault/reporting-intelligence"
                  element={<ReportingIntelligence />}
                />
              </Route>
              <Route path="/asset-management" element={<PrivateRoute />}>
                <Route path="/asset-management" element={<AssetManagement />} />
              </Route>
              <Route
                path="/asset-management/add-new-asset"
                element={<PrivateRoute />}
              >
                <Route
                  path="/asset-management/add-new-asset"
                  element={<AddNewAsset />}
                />
              </Route>
              <Route
                path="/asset-management/edit-asset/:id"
                element={<PrivateRoute />}
              >
                <Route
                  path="/asset-management/edit-asset/:id"
                  element={<AddNewAsset />}
                />
              </Route>
              <Route
                path="/asset-management/view-assets/:assetId"
                element={<PrivateRoute />}
              >
                <Route
                  path="/asset-management/view-assets/:assetId"
                  element={<ViewAssets />}
                />
              </Route>
              <Route
                path="/asset-management/view-assets/:assetId/view-tenants/:tenantId"
                element={<PrivateRoute />}
              >
                <Route
                  path="/asset-management/view-assets/:assetId/view-tenants/:tenantId"
                  element={<ViewTenants />}
                />
              </Route>

              <Route path="/reporting" element={<PrivateRoute />}>
                <Route path="/reporting" element={<Reporting />} />
              </Route>

              <Route path="/settings" element={<PrivateRoute />}>
                <Route path="/settings" element={<Settings />} />
              </Route>
              {/* <Route path="/targets" element={<Targets />}>
              <Route path="add-new-target" element={<AddNewTargets />} />
            </Route>
            <Route path="/" element={<Navigate to="/targets" replace />} /> */}
            </Routes>
          </Layout>
          {/* </Router> */}
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
