/** @format */

import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BarChart from '../components/charts/BarChart';
import DoughnutChart from '../components/charts/Doughnut';
import {
  waterBarChart,
  waterDoughnutChart,
} from '../services/private/water/WaterService';
import { useSelector } from 'react-redux';

const backgroundColors = ['#A155B9', '#165BAA', '#F765A3', '#16BFD6'];
const WaterAnalytics = () => {
  const [barData, setBarData] = useState([]);
  const [doughnutData, setDoughnutData] = useState([]);
  const chartData = {
    labels: barData.map((data) => data.year),
    datasets: [
      {
        label: 'Water Consumption',
        type: 'line',
        data: barData.map((data) => data.sum),
        borderColor: '#6DB3B5',
        fill: true,
        tension: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      {
        label: 'Water Consumption',
        type: 'bar',
        data: barData.map((data) => data.sum),
        backgroundColor: '#165BAA',
        barThickness: 32,
      },
    ],
  };
  const pieData = {
    labels: doughnutData.map((data) => data.location),
    datasets: [
      {
        label: 'Water Consumption',
        type: 'doughnut',
        data: doughnutData.map((data) => data.percentage),
        backgroundColor: backgroundColors,
        borderWidth: 0,
      },
    ],
  };

  const { fy_start, fy_end } = useSelector((state) => state.years.selectedYear);

  async function fetchDoughnutChartData(fyStart, fyEnd) {
    try {
      const res = await waterDoughnutChart(fyStart, fyEnd);
      setDoughnutData(res.data);
    } catch (err) {
      // console.log(err);
    }
  }
  async function fetchBarChartData() {
    try {
      const res = await waterBarChart();
      setBarData(res.data);
    } catch (err) {
      // console.log(err);
    }
  }
  useEffect(() => {
    fetchDoughnutChartData(fy_start, fy_end);
  }, [fy_start, fy_end]);

  useEffect(() => {
    fetchBarChartData();
  }, []);

  const updatedDoughnutData = doughnutData.map((data, index) => ({
    ...data,
    backgroundColor: backgroundColors[index],
  }));
  return (
    <Stack gap="24px">
      <BarChart
        chartData={chartData}
        yAxisTitle="Water Consumption [m3]"
        chartTitle="Yearly Water Consumption"
        aspectRatio={5}
      />
      <DoughnutChart
        chartData={pieData}
        labelsData={updatedDoughnutData}
        type="Total Water Consumption"
        chartHeight={300}
      />
    </Stack>
  );
};

export default WaterAnalytics;
