/** @format */

import { Avatar, Box, Button, Card, Stack, Typography } from '@mui/material';
import React from 'react';
import styles from './sdgCard.module.css';
import { useNavigate } from 'react-router-dom';

const SdgCard = ({ item, index }) => {
  const navigate = useNavigate();
  return (
    <Card
      className={styles.cardContainer}
      sx={{ padding: '16px' }}
      elevation={0}
    >
      <Stack gap={2}>
        <Stack direction="row" gap={2} alignItems="center">
          <Avatar />
          <Typography variant="h6" className={styles.cardTitle}>
            {item.title}
          </Typography>
        </Stack>
        <Typography variant="p" className={styles.cardPara}>
          {item.description}
        </Typography>
      </Stack>
      <Box>
        <Typography className={styles.questionCount} marginBottom="10px">
          {`Questions Answered: ${item.answered_questions}/${item.total_questions}`}
        </Typography>
        <Button
          variant="contained"
          sx={{ color: '#fff' }}
          onClick={() => navigate(`${index + 1}`, { state: { sdgData: item } })}
        >
          Begin
        </Button>
      </Box>
    </Card>
  );
};

export default SdgCard;
