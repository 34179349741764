import React, { useEffect, useState } from 'react'
import { Modal, Box, Typography, TextField, Button, Grid } from '@mui/material';

const TenantModal = ({ open, handleClose,handleAddTenant, tenantData, handleUpdate }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const [formData, setFormData] = useState({
        tenant_name: "",
        floor: "",
        suite: "",
        area_leased: "",
        commencement_date: "",
        expiry_date: "",
        rent: ''
    })
    const [error, setError] = useState({
        tenant_name: "",
        floor: "",
        suite: "",
        area_leased: "",
        commencement_date: "",
        expiry_date: "",
        rent:"",
        message: "",

    })
    useEffect(() => {
        if (tenantData) {
            setFormData({
                tenant_name: tenantData.tenant_name || '',
                floor: tenantData.floor || '',
                suite: tenantData.suite || '',
                area_leased: tenantData.area_leased || '',
                commencement_date: tenantData.commencement_date || '',
                expiry_date: tenantData.expiry_date || '',
                rent: tenantData.rent || ''
            })
        } else {
            setFormData({
                tenant_name: "",
                floor: "",
                suite: "",
                area_leased: "",
                commencement_date: "",
                expiry_date: "",
                rent: ''
            })
        }
    }, [tenantData])

    const validateForm = () => {
        const newErrors = {};
        let valid = true;

        // Required fields
        if (!formData.tenant_name) {
            newErrors.tenant_name = "Tenant name is required";
            valid = false;
        }
        if (!formData.floor) {
            newErrors.floor = "Floor is a required field";
            valid = false;
        } else if (isNaN(formData.floor)) {
            newErrors.floor = "Floor must be a valid number";
            valid = false;
        }
    
        // Suite validation
        if (!formData.suite) {
            newErrors.suite = "Suite is a required field";
            valid = false;
        }
    
        // Area Leased validation
        if (!formData.area_leased) {
            newErrors.area_leased = "Area Leased is a required field";
            valid = false;
        } else if (isNaN(formData.area_leased)) {
            newErrors.area_leased = "Area Leased must be a valid number";
            valid = false;
        }
    
        // Rent validation
        if (!formData.rent) {
            newErrors.rent = "Rent is a required field";
            valid = false;
        } else if (isNaN(formData.rent)) {
            newErrors.rent = "Rent must be a valid number";
            valid = false;
        }
        if (!formData.commencement_date) {
            newErrors.commencement_date = "Commencement Date is required";
            valid = false;
        }
        if (!formData.expiry_date) {
            newErrors.expiry_date = "Expiry Date is required";
            valid = false;
        }

        // Commencement Date should be before Expiry Date
        if (formData.commencement_date && formData.expiry_date) {
            if (new Date(formData.commencement_date) >= new Date(formData.expiry_date)) {
                newErrors.commencement_date = "Commencement Date should be before Expiry Date";
                newErrors.expiry_date = "Expiry Date should be after Commencement Date";
                valid = false;
            }
        }

        setError(newErrors);
        return valid;
    };
    const handleAddSubmit = async(e) => {
        e.preventDefault()
        if (!validateForm()) {
            return
        }  
        await  handleAddTenant(formData)
    }
    const handleUpdateSubmit = async (e) =>{
        e.preventDefault()
        if(!validateForm()){
            return
        }
        await handleUpdate(formData)
    }
    
    return (
        <>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" gutterBottom>
                        {tenantData ? 'Update Tenant' : 'Add Tenant'}
                    </Typography>
                    <form onSubmit={tenantData ? handleUpdateSubmit : handleAddSubmit } >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Tenant Name"
                                    name="tenantName"
                                    value={formData.tenant_name}
                                    onChange={(e) => setFormData((prevState) => ({ ...prevState, tenant_name: e.target.value }))}
                                    error={!!error.tenant_name}
                                    helperText={error.tenant_name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Floor"
                                    value={formData.floor}
                                    onChange={(e) => {
                                        const sanitizedValue = e.target.value.replace(/[a-zA-Z\s]+/g, '');
                                        setFormData((prevState) => ({ ...prevState, floor:sanitizedValue }))}}
                                    error= {!!error.floor}
                                    helperText={error.floor}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Suite"
                                    value={formData.suite}
                                    onChange={(e) => setFormData((prevState) => ({ ...prevState, suite: e.target.value }))}
                                    error={!!error.suite}
                                    helperText={error.suite}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Area Leased"
                                    value={formData.area_leased}
                                    onChange={(e) =>{
                                        const sanitizedValue = e.target.value.replace(/[a-zA-Z\s]+/g, '');
                                        setFormData((prevState) => ({ ...prevState, area_leased: sanitizedValue }))}}
                                    error={!!error.area_leased}
                                    helperText={error.area_leased}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Commencement Date"
                                    type="date"
                                    value={formData.commencement_date}
                                    onChange={(e) => setFormData((prevState) => ({ ...prevState, commencement_date: e.target.value }))}
                                    InputLabelProps={{ shrink: true }}
                                    error={!!error.commencement_date}
                                    helperText={error.rent}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Expiry Date"
                                    type="date"
                                    value={formData.expiry_date}
                                    onChange={(e) => setFormData((prevState) => ({ ...prevState, expiry_date: e.target.value }))}
                                    InputLabelProps={{ shrink: true }}
                                    error={!!error.expiry_date}
                                    helperText={error.expiry_date}

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Rent"
                                    value={formData.rent}
                                    onChange={(e) => {
                                        const sanitizedValue = e.target.value.replace(/[a-zA-Z\s]+/g, '');
                                        setFormData((prevState) => ({ ...prevState, rent: sanitizedValue }))}}
                                    error={!!error.rent}
                                    helperText={error.rent}
                                />
                            </Grid>
                        </Grid>
                        <Box mt={3} textAlign="right">
                            <Button variant="contained" color="primary" type="submit">
                                {tenantData ? "Update Tenant" : "Add Tenant"}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </>
    )
}

export default TenantModal