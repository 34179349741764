import { createSlice } from "@reduxjs/toolkit";

const yearsSlice = createSlice({
    name:  'years',
    initialState:{
        years:[],
        selectedYear: {fy_start:"", fy_end:""},
    },
    reducers:{
        setYears: (state , action) =>{
            state.years = action.payload;
        },
        setSelectedYear: (state, action) => {
            state.selectedYear = action.payload
        },
    },

})
export const { setYears, setSelectedYear } = yearsSlice.actions;
export default yearsSlice.reducer;