/** @format */

import {
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,
  TableFooter,
  Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './assetManagement.module.css';
import { DeleteOutline, OpenInNew } from '@mui/icons-material';
import theme from '../theme';
import { useNavigate, Link } from 'react-router-dom';
import EditIcon from '../../assets/icons/EditIcon';
import config from '../../config/config.json'
import {
  getAssetsList,
  deleteAsset,
  getAllAssets
} from '../../services/private/assetManagement/assetServices';
import { debounce } from '../../services/helpers/helper';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
// import { errorToast } from '../../utils/alerts';
import { errorToast, successToast } from '../../utils/alerts';
import DeleteModal from '../../modals/DeleteModal';

const attachmentntFormatter = (param) => {
  const arr = param.split('/');
  const n = arr.length;
  return arr[n - 1];
};
const OurPortfolio = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0); // Page should start from 0
  const [data, setData] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [selectedRowId, setSelectedRowId] = useState(null)


  const rowsPerPage = 10;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDeleteModalOpen = async (id) => {
    setSelectedRowId(id)
    setDeleteDialog(true)
  }

  const handleDelete = async (id) => {
    try {
      const respomnse = await deleteAsset(id);
      successToast(respomnse.message)
      serverRequest(page)

    } catch (error) {
      errorToast(error.message)
    }
  };

  const handleInputChange = (event) => {
    const query = event.target.value;
    console.log("query:", query);
    // setSearchTerm(query);
    handleSearch(query);
  };

  const handleEdit = (param) => {
    navigate(`/asset-management/edit-asset/${param.id}`, { state: param });
  };
  const handleSearch = debounce(async (query) => {
    if (!query.trim()) {
      await serverRequest(page)
      return
    }

    const entities = await getAllAssets()
    const lowerCaseQuery = query.toLowerCase();
    const matchedAssets = entities.data.filter((asset) =>
      asset.name.toLowerCase().includes(lowerCaseQuery) || asset.property_type.toLowerCase().includes(lowerCaseQuery) || asset.country.toLowerCase().includes(lowerCaseQuery) || asset.state.toLowerCase().includes(lowerCaseQuery) || asset.city.toLowerCase().includes(lowerCaseQuery)
    );
    console.log("search results:", matchedAssets);
    setData(matchedAssets);
  }, 500);


  async function serverRequest(par) {
    try {
      const { entities } = await getAssetsList(par);
      // console.log(222, entities);
      setData(entities);
    } catch (error) {
      errorToast(error.message || 'Failed to  fetch Asset List!');
    }
  }

  useEffect(() => {
    serverRequest(page);
  }, [page]);

  return (
    <Stack className={styles.container} gap={2} p={3}>
      <Typography variant="h5" className={styles.pageTitle}>
        Our Portfolio
      </Typography>
      <Typography variant="body" className={styles.description}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages,
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <TextField
          variant="outlined"
          label="Search"
          placeholder="Asset Name, Property Type, Country, State, City"
          size="small"
          onChange={handleInputChange}
        />
        <Button
          variant="contained"
          style={{ color: '#fff' }}
          onClick={() => navigate('add-new-asset')}
        >
          Add New Asset
        </Button>
      </Stack>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: theme.palette.esgblueblack.main }}>
              <TableCell className={styles.tableHeadCell}>Asset Name</TableCell>
              <TableCell className={styles.tableHeadCell}>
                Property Type
              </TableCell>
              <TableCell className={styles.tableHeadCell}>Country</TableCell>
              <TableCell className={styles.tableHeadCell}>City/State</TableCell>
              <TableCell className={styles.tableHeadCell}>Size (M2)</TableCell>
              <TableCell className={styles.tableHeadCell}>
                Building Certification
              </TableCell>
              <TableCell className={styles.tableHeadCell}>Valuation</TableCell>
              <TableCell className={styles.tableHeadCell} align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className={styles.tableBodyCell}>
                      {item.name}
                    </TableCell>
                    <TableCell className={styles.tableBodyCell}>
                      {item.property_type}
                    </TableCell>
                    <TableCell className={styles.tableBodyCell}>
                      {item.country}
                    </TableCell>
                    <TableCell className={styles.tableBodyCell}>
                      {item.city}
                    </TableCell>
                    <TableCell className={styles.tableBodyCell}>
                      {item.size}
                    </TableCell>
                    <TableCell className={styles.tableBodyCell}>
                      {attachmentntFormatter(item.attachment)}
                    </TableCell>
                    <TableCell className={styles.tableBodyCell}>
                      {item.value ? `$${item.value}` : 'NA'}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" gap={5} justifyContent="flex-end">
                        <IconButton
                          size="small"
                          sx={{ color: theme.palette.esgblueblack.main }}
                        >
                          <Link style={{ textDecoration: "none", color: "inherit" }} target="_blank" to={config.BUCKET_URL + item.attachment} >
                            <OpenInNew />
                          </Link>
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleEdit(item)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          sx={{ color: theme.palette.esgblueblack.main }}
                          onClick={() => handleDeleteModalOpen(item.id)}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                  No Data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {data.length > 0 && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={8}
                  count={data.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  ActionsComponent={TablePaginationActions} // Custom pagination actions
                  rowsPerPageOptions={[]} // Remove rows per page options
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} of ${count}`
                  }
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {deleteDialog && (
        <DeleteModal
          open={deleteDialog}
          handelClose={() => {
            setDeleteDialog(false);
            setSelectedRowId(null);
          }}
          label={"Are you sure you want to delete this entry?"}
          description={"By pressing this delete button will remove this Document permanently."}
          id={selectedRowId}
          deleteRow={handleDelete}
        />
      )}
    </Stack>
  );
};

export default OurPortfolio;

function TablePaginationActions({ count, page, onPageChange }) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / 10) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / 10) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / 10) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
