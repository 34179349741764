/** @format */

import {
  AppBar,
  Avatar,
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from '@mui/material';
import React, { useEffect } from 'react';
import './header.css';
import { useLocation } from 'react-router-dom';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setYears, setSelectedYear } from '../store/header/yearSlice';
import Config from '../config/config.json';
// const yearList = [
//   "2025-26",
//   "2024-25",
//   "2023-24",
//   "2022-23",
//   "2021-22",
//   "2020-21",
//   "2019-20",
//   "2018-19",
// ];
const Header = () => {
  const dispatch = useDispatch();
  const years = useSelector((state) => state.years.years);
  const selectedYear = useSelector((state) => state.years.selectedYear);
  const userDetails = useSelector((state) => state.auth.user);
  const location = useLocation();
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearList = Array.from({ length: 10 }, (_, i) => {
      const fy_start = currentYear - i;
      const fy_end = currentYear + 1 - i;
      return `${fy_start}-${fy_end.toString().slice(2)}`;
    });
    const parsedYears = yearList.map((year) => {
      const [fy_start, fy_end] = year
        .split('-')
        .map((part) => (part.length === 2 ? `20${part}` : part));
      return { fy_start, fy_end };
    });
    dispatch(setYears(parsedYears));
    const defaultYear = parsedYears.find(
      (year) =>
        year.fy_start === currentYear.toString() &&
        year.fy_end === (currentYear + 1).toString()
    );
    if (defaultYear) {
      // Set the default selected financial year based on the current year
      dispatch(setSelectedYear(defaultYear));
    }
  }, [dispatch]);

  const handleYearChange = (e) => {
    const [fy_start, fy_end] = e.target.value.split('-');
    dispatch(setSelectedYear({ fy_start, fy_end }));
  };
  const headerTitle = () => {
    let title = '';
    let headerTitle = '';

    switch (true) {
      case location.pathname === '/':
        title = 'ESG App | DashBoard';
        headerTitle = 'Dashboard';
        break;
      case location.pathname.includes('targets'):
        title = 'ESG App | Targets';
        headerTitle = 'Targets';
        break;
      case location.pathname.includes('energy'):
        title = 'ESG App | Energy Consumption';
        headerTitle = 'Energy Consumption';
        break;
      case location.pathname.includes('scope1'):
        title = 'ESG App | Scope 1';
        headerTitle = 'Scope 1';
        break;
      case location.pathname.includes('scope2'):
        title = 'ESG App | Scope 2';
        headerTitle = 'Scope 2';
        break;
      case location.pathname.includes('scope3'):
        title = 'ESG App | Scope 3';
        headerTitle = 'Scope 3';
        break;
      case location.pathname.includes('water'):
        title = 'ESG App | Water Consumption';
        headerTitle = 'Water Consumption';
        break;
      case location.pathname.includes('waste'):
        title = 'ESG App | Waste Consumption';
        headerTitle = 'Waste Consumption';
        break;
      case location.pathname.includes('data-vault'):
        title = 'ESG App | Data Vault';
        headerTitle = 'Data Vault';
        break;
      case location.pathname.includes('asset-management'):
        title = 'ESG App | Assets Under Management';
        headerTitle = 'Assets Under Management';
        break;
      case location.pathname.includes('reporting'):
        title = 'ESG App | Reporting';
        headerTitle = 'Reporting';
        break;
      case location.pathname.includes('settings'):
        title = 'ESG App | Settings';
        headerTitle = 'Settings';
        break;
      default:
        // Default title and headerTitle if no conditions are met
        title = 'ESG App';
      //     headerTitle = "Dashboard";
    }

    document.title = title;
    return <p className="header-title">{headerTitle}</p>;
  };
  return (
    <AppBar
      className="header"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        bgcolor: '#fff',
        boxShadow: '0px 4px 26px -1px rgba(189, 195, 221, 0.38)',
      }}
      elevation={1}
    >
      <span className="left-header">{headerTitle()}</span>
      <span className="right-header">
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedYear?.fy_start + '-' + selectedYear?.fy_end || ''}
              onChange={handleYearChange}
              renderValue={(value) => (value !== '' ? `F.Y. ${value}` : '')}
            >
              {years.map((year, idx) => (
                <MenuItem
                  key={idx}
                  value={`${year.fy_start}-${year.fy_end}`}
                  id="input-dropdown"
                >
                  {`F.Y.${year.fy_start}-${year.fy_end}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <IconButton>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Frame">
              <path
                id="Vector"
                d="M9.99935 16.6667C8.16602 16.6667 6.66602 18.1667 6.66602 20.0001C6.66602 21.8334 8.16602 23.3334 9.99935 23.3334C11.8327 23.3334 13.3327 21.8334 13.3327 20.0001C13.3327 18.1667 11.8327 16.6667 9.99935 16.6667ZM29.9993 16.6667C28.166 16.6667 26.666 18.1667 26.666 20.0001C26.666 21.8334 28.166 23.3334 29.9993 23.3334C31.8327 23.3334 33.3327 21.8334 33.3327 20.0001C33.3327 18.1667 31.8327 16.6667 29.9993 16.6667ZM19.9993 16.6667C18.166 16.6667 16.666 18.1667 16.666 20.0001C16.666 21.8334 18.166 23.3334 19.9993 23.3334C21.8327 23.3334 23.3327 21.8334 23.3327 20.0001C23.3327 18.1667 21.8327 16.6667 19.9993 16.6667Z"
                fill="#82959C"
              />
              <path
                id="Vector_2"
                d="M9.99935 6.66675C8.16602 6.66675 6.66602 8.16675 6.66602 10.0001C6.66602 11.8334 8.16602 13.3334 9.99935 13.3334C11.8327 13.3334 13.3327 11.8334 13.3327 10.0001C13.3327 8.16675 11.8327 6.66675 9.99935 6.66675ZM29.9993 6.66675C28.166 6.66675 26.666 8.16675 26.666 10.0001C26.666 11.8334 28.166 13.3334 29.9993 13.3334C31.8327 13.3334 33.3327 11.8334 33.3327 10.0001C33.3327 8.16675 31.8327 6.66675 29.9993 6.66675ZM19.9993 6.66675C18.166 6.66675 16.666 8.16675 16.666 10.0001C16.666 11.8334 18.166 13.3334 19.9993 13.3334C21.8327 13.3334 23.3327 11.8334 23.3327 10.0001C23.3327 8.16675 21.8327 6.66675 19.9993 6.66675Z"
                fill="#82959C"
              />
              <path
                id="Vector_3"
                d="M9.99935 26.6667C8.16602 26.6667 6.66602 28.1667 6.66602 30.0001C6.66602 31.8334 8.16602 33.3334 9.99935 33.3334C11.8327 33.3334 13.3327 31.8334 13.3327 30.0001C13.3327 28.1667 11.8327 26.6667 9.99935 26.6667ZM29.9993 26.6667C28.166 26.6667 26.666 28.1667 26.666 30.0001C26.666 31.8334 28.166 33.3334 29.9993 33.3334C31.8327 33.3334 33.3327 31.8334 33.3327 30.0001C33.3327 28.1667 31.8327 26.6667 29.9993 26.6667ZM19.9993 26.6667C18.166 26.6667 16.666 28.1667 16.666 30.0001C16.666 31.8334 18.166 33.3334 19.9993 33.3334C21.8327 33.3334 23.3327 31.8334 23.3327 30.0001C23.3327 28.1667 21.8327 26.6667 19.9993 26.6667Z"
                fill="#82959C"
              />
            </g>
          </svg>
        </IconButton>
        <span className="header-user-details">
          <Avatar
            src={`${Config.API_BASE_URL}/${userDetails.logo}`}
            className="avatar-icon"
          />
          <p className="header-user">
            {userDetails.firstName + ' ' + userDetails.lastName}
          </p>
          <IconButton>
            <KeyboardArrowDown />
          </IconButton>
        </span>
      </span>
    </AppBar>
  );
};

export default Header;
