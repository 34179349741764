import React, { useEffect, useCallback, useState } from 'react'
import ScrollableTable from './ScrollableTable'
import { efficiencyMeasures } from '../../constant/assetManagement'
import { addEfficiencyAsset, deleteEfficiencyAsset, getEfficiencyAsset, updateEfficiencyAsset } from '../../services/private/assetManagement/assetServices'
import { errorToast } from '../../utils/alerts'
const EfficiencyDataTable = ({addField, setAddField, searchQuery}) => {
    const [data , setData] = useState([])
    const [originalData, setOriginalData] = useState([])
    const [currentPage ,  ] = useState(1)
    const [trigger, setTrigger] = useState(0)
    const limit = 10

    const serverRequest = useCallback(async () => {
        try {
          const entities = await getEfficiencyAsset(limit, currentPage);
          setData(entities.entities);
          setOriginalData(entities.entities);
        } catch (error) {
          errorToast(error.message);
        }
      }, [limit, currentPage]); // Dependencies for limit and currentPage
      
      useEffect(() => {
        serverRequest();
      }, [serverRequest, trigger]);
      const handleAddAsset = async(formData) => {
        const res = await addEfficiencyAsset(formData)
        if(res.type === "RXSUCCESS"){
          setTrigger((prev)=> prev+1)
        }
        return res
      }
      const handleDeleteAsset = async (id)=>{
        const res = await deleteEfficiencyAsset(id)
        if(res.type==="RXSUCCESS"){
          setTrigger((prev) => prev + 1); // Toggle refreshTrigger to re-fetch data
        }
        return res
      }
      const handleUpdateAsset = async (payload, id) => {
        const res = await updateEfficiencyAsset(payload, id)
        if(res.type === 'RXSUCCESS'){
          setTrigger((prev) => prev + 1); // Toggle refreshTrigger to re-fetch data
        }
        return res
      }
      useEffect(()=>{
        if(searchQuery){
          // setData(originalData)
          const searchTerm = searchQuery.toLowerCase()
          const filteredData = originalData.filter((item) =>
            item.name?.toLowerCase().includes(searchTerm)
          );
          setData(filteredData)
        }else {
          setData(originalData)
        }
      },[searchQuery, originalData])
  return (
    <div>
        <ScrollableTable
            tableContent={efficiencyMeasures}
            colLength={9}
            options={["True", "False", "N/A"]}
            assetData={data}
            addField={addField}
            setAddField={setAddField}
            postRequest={handleAddAsset}
            deleteRequest = {handleDeleteAsset}
            putRequest={handleUpdateAsset}
          />
    </div>
  )
}

export default EfficiencyDataTable