/** @format */

import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { ArrowDropDown } from '@mui/icons-material'; // Import the MUI icon
import { useTheme } from '@mui/material/styles'; // Import MUI theme

// Custom DropdownIndicator using MUI's ArrowDropDown icon
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDown />
    </components.DropdownIndicator>
  );
};

// Custom styles to match MUI Select style
const customStyles = (theme, isFocused) => ({
  control: (provided, state) => ({
    ...provided,
    border: isFocused
      ? `2px solid ${theme.palette.primary.main}`
      : '1px solid #bdbdbd',
    borderRadius: '4px',
    minHeight: '40px', // Adjust height for small size
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.palette.primary.light,
    },
    position: 'relative',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 8px', // Adjust padding to match MUI dropdown
  }),
  indicatorSeparator: () => ({
    display: 'none', // Hide the separator between the dropdown indicator and the input
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: isFocused ? theme.palette.primary.main : '#757575',
    position: 'absolute',
    top: state.hasValue || state.selectProps.inputValue ? -10 : '50%',
    fontSize: state.hasValue || state.selectProps.inputValue ? '12px' : '14px',
    transition: 'all 0.2s ease',
    left: '12px',
    backgroundColor: '#fff',
    padding: '0 4px',
    zIndex: 1,
  }),
});

// Main component
const CustomReactSelect = ({ options, value, onChange, placeholder, isDisabled }) => {
  const theme = useTheme(); // Access the MUI theme
  const [isFocused, setIsFocused] = useState(false); // Track focus state

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div style={{ position: 'relative' }}>
      <label
        htmlFor="react-select"
        style={{
          position: 'absolute',
          top: isFocused || value ? -8 : '50%',
          left: '12px',
          fontSize: isFocused || value ? '12px' : '16px',
          //   color: isFocused ? theme.palette.primary.main : '#757575',
          transition: 'all 0.2s ease',
          pointerEvents: 'none',
          background: '#fff',
          padding: '0 4px',
          zIndex: 1,
          //   lineHeight: !isFocused && !value && 0.1,
          color: isFocused ? theme.palette.primary.main : '#757575',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          fontWeight: 400,
          //   fontSize: '1rem',
          lineHeight: !isFocused && !value && 0.1,
          letterSpacing: '0.00938em',
        }}
      >
        {placeholder}
      </label>
      <Select
        id="react-select"
        options={options}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        components={{ DropdownIndicator }} // Use custom DropdownIndicator
        styles={customStyles(theme, isFocused)} // Apply custom styles
        placeholder=""
        // error={error}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default CustomReactSelect;
