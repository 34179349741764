/** @format */

import axios from 'axios';
import config from '../../../config/config.json';
import { headers } from '../../helpers/helper';

const PREFIX = 'carbon/datavault/';

export const getDataVault = async (tab, module) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${
        config.API_URL + PREFIX
      }governance/getQuestions?tab=${tab}&module=${module}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

export const postDataVault = async (param) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}governance/updateAnswer`,
      param,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};
export const postDataVaultFileUpload = async (param) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}governance/updateAnswerDoc/sub`,
      param,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};
export const postDataVaultFileUploadSub = async (param, id) => {
  try {
    const auth = await headers(true);
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}governance/updateAnswerDoc/sub/${id}`,
      param,
      {
        headers: auth,
      }
    );
    return apiResponse?.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

// ***************************************** SDGs ************************************
export const getSDGs = async () => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}sdg/getCards`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

export const getSDGQustions = async (param) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}sdg/getQuestions/${param}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

export const postQuestionResponse = async (param) => {
  try {
    const isFormData = param instanceof FormData;
    const auth = await headers(isFormData);
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}sdg/answerQuestion`,
      param,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

// XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX SDGs XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

// ****************************************** TCFD *************************************
export const getTCFDQustions = async (param) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}tcfd/getQuestions/${param}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

export const postTCFQuestionResponse = async (param) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}tcfd/createAnswers`,
      param,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};
// ---------------------------------------------------------------------------- Share DataVault ---------------------------------------------------------------------------- \\

export const shareDataVault = async (payload, query)=>{
  try {
    const auth = await headers()
    const apiResponse = await axios.post(`${config.API_URL + PREFIX}share/getData?${query}`,payload,{
      headers: auth,
    })
    return apiResponse.data
  } catch (error) {
    throw new Error(error.response.data.message || 'Oops some error occured!')
  }
}