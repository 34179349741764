/** @format */

import React, { useEffect, useState } from 'react';
import DataVault from '../DataVault';
import { Stack, Tab, Typography } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import styles from '../governance/leadershipCommitments.module.css';
import { ExpandMore } from '@mui/icons-material';
import TcfdAccordion from './TcfdAccordion';
import { getTCFDQustions } from '../../../services/private/dataVault/dataVaultService';

const Tcfd = () => {
  const [value, setValue] = useState(1);
  // eslint-disable-next-line
  const [answered, setAnswered] = useState(0);
  const [data, setData] = useState([]);
  const handleInnerTab = (event, newValue) => {
    setValue(newValue);
  };

  const getData = async (param) => {
    const res = await getTCFDQustions(
      param === 1
        ? 'governance'
        : param === 2
        ? 'strategy'
        : param === 3
        ? 'risk'
        : 'metrics'
    );
    setData(res.data);
  };

  useEffect(() => {
    getData(value);
  }, [value]);
  return (
    <DataVault>
      <Stack gap="16px" className={styles.leadershipContainer}>
        <Typography variant="h5" className={styles.pageHeading}>
          TCFD
        </Typography>
        <Typography variant="p" className={styles.explaination}>
          This will be a brief explanation of the Corporate ESG section. Lorem
          Ipsum is simply dummy text of the printing and typesetting industry.
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages,
        </Typography>
        <Stack>
          <Typography className={styles.questionCount} pb={1}>
            Questions Answered: {answered}/23
          </Typography>
          <TabContext value={value}>
            <TabList onChange={handleInnerTab}>
              <Tab label="1.	Governance" value={1}>
                <ExpandMore />
              </Tab>
              <Tab label="2. Strategy" value={2} />
              <Tab label="3. Risk Management" value={3} />
              <Tab
                label="4. Metrics and Targets"
                value={4}
                sx={{ maxWidth: '630px' }}
              />
            </TabList>
            {Array(4)
              .fill(null)
              .map((_, item) => (
                <TabPanel value={item + 1} key={item}>
                  <TcfdAccordion data={data} />
                </TabPanel>
              ))}
          </TabContext>
        </Stack>
      </Stack>
    </DataVault>
  );
};

export default Tcfd;
