/** @format */

import React, { useState } from 'react';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { generateUniqueID } from '../../utils/UniversalFunction';
import { Add, Delete } from '@mui/icons-material';
import './addNewTargets.css';
import { errorToast } from '../../utils/alerts';
const AddAction = ({
  handleNext,
  handleBack,
  targetDetails,
  actions,
  setActions,
}) => {
  const [plan, setPlan] = useState(null);
  const [error, setError] = useState(false);

  const handelPlanSubmit = async () => {
    if (plan === null || plan === '') {
      setError(true);
      errorToast('Required field');
    } else {
      const id = generateUniqueID();
      let tempArr = actions;
      tempArr.push({ id: id, name: plan });
      setActions(tempArr);
      setPlan('');
    }
  };

  const handelPlanDelete = async (id) => {
    let temp = [...actions];
    let index = temp.findIndex((item) => item?.id === id);
    temp.splice(index, 1);
    setActions(temp);
  };

  return (
    <Stack direction="column" gap={3} width="800px">
      <Box>
        <Typography variant="p" className="action-plan-headline">
          {`  ${targetDetails?.shift === 0 ? 'Decrease' : 'Increase'} ${
            targetDetails?.shift_value +
            (targetDetails?.shift_type === 0 ? '%' : '')
          } of emission created ${
            targetDetails?.type === '1' ||
            targetDetails?.type === '2' ||
            targetDetails?.type === '3'
              ? 'by ' +
                targetDetails?.division +
                '(' +
                targetDetails?.category +
                ')'
              : ''
          } at ${targetDetails?.location + ' office'} by ${
            targetDetails?.achieve_by?.$y
          }
          `}
        </Typography>
        <TextField
          error={error}
          label="Enter Action Plan"
          id="outlined-error-helper-text"
          helperText={error ? 'Required field.' : ''}
          sx={{ width: 800 }}
          value={plan || ''}
          multiline
          onChange={(e) => {
            setError(false);
            setPlan(e.target.value);
          }}
        />
        <Box className="add-action-plan">
          <Button
            variant="outlined"
            endIcon={<Add />}
            onClick={handelPlanSubmit}
            sx={{
              padding: '8px 16px',
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              borderRadius: '100px',
              backgroundColor: '#F6F7FB',
              color: '#4F5367',
              border: '1px solid #E8EBF2',
              background: '#F6F7FB',
            }}
          >
            Add Action Plan
          </Button>
        </Box>

        {actions.map((plan) => (
          <TextField
            disabled
            key={plan.id}
            variant="outlined"
            sx={{ width: 800, marginBottom: '10px', paddingTop: '12px' }}
            defaultValue={plan?.name}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handelPlanDelete(plan?.id)}>
                    <Delete color="error" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
      </Box>
      <Stack direction="row" mt={3}>
        <Button
          variant="outlined"
          sx={{ mr: 1, color: '#33ae5e' }}
          disabled={false}
          onClick={handleBack}
        >
          Back
        </Button>
        <Button variant="contained" sx={{ color: '#fff' }} onClick={handleNext}>
          Next
        </Button>
      </Stack>
    </Stack>
  );
};

export default AddAction;
