/** @format */

// const headers = () => {
//   const user = JSON.parse(localStorage.getItem('user'));
//   const headers = {
//     Authorization: `Bearer ${user.token}`,
//     'content-type': 'application/json',
//   };
//   return headers;
// };

const headers = (isFormData = false) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const headers = {
    Authorization: `Bearer ${user.token}`,
  };

  // Only set 'Content-Type' if not sending FormData
  if (isFormData) {
    headers['Content-Type'] = 'multipart/form-data';
  } else {
    headers['Content-Type'] = 'application/json';
  }

  return headers;
};
const debounce = (func, delay) =>{
  let timeout 
  return (...args)=>{
    clearTimeout(timeout)
    timeout = setTimeout(()=> func(...args), delay)
  }
}
module.exports = {
  headers,
  debounce
};
