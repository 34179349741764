import { useState, useEffect } from 'react';
import { errorToast } from '../utils/alerts';

// Custom hook to group assets by batch ID
const useGroupedAssets = (fetchAssets, trigger) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function serverRequest() {
      try {
        const entities = await fetchAssets();
        if (entities?.data) {
          groupAssetsByBatchId(entities.data);
        }
      } catch (error) {
        errorToast(error.message)
      }
    }
    serverRequest();
  }, [fetchAssets, trigger]);

  const groupAssetsByBatchId = (data) => {
    const groupedAssets = [];
    const assetMap = {};

    data.forEach((asset) => {
      if (!assetMap[asset.batch_id]) {
        assetMap[asset.batch_id] = {
          batch_id: asset.batch_id,
          asset_name: asset.asset_name,
          property_type: asset.property_type,
          assets: [],
        };
        groupedAssets.push(assetMap[asset.batch_id]);
      }

      const { batch_id, asset_name, company_id, property_type, ...rest } = asset;
      assetMap[asset.batch_id].assets.push({
        ...rest,
      });
    });

    setData(groupedAssets);
    return groupedAssets;
  };

  return data;
};

export default useGroupedAssets;
