/** @format */

export const assetResilience = [
  [
    {
      title: "Biodiversity and habitat",
      tooltip:
        "1.	Biodiversity and habitat: Issues related to wildlife, endangered species, ecosystem services, habitat management, and relevant topics. Biodiversity refers to the variety of all plant and animal species. Habitat refers to the natural environment in which these plant and animal species live and function.",
        handle:"biodiversity_habitat"
      },
      {
        title: "Building safety and materials",
        tooltip:
        "2.	Building safety and materials: Assessment to identify potential hazards resulting, for example, from vulnerabilities caused by the construction materials used, major structural flaws or the presence of asbestos.",
        handle:"safety_materials"
      },
      {
        title: "Climate change adaptation",
        tooltip:
        "3.	Climate change adaptation: Preparation for long-term change in climatic conditions or climate related events. Example of climate change adaptation measures can include, but are not limited to: building flood defences, xeriscaping and using tree species resistant to storms and fires, adapting building codes to extreme weather events.",
        handle:"climate_change"
      },
      {
        title: "Contaminated land",
        tooltip:
        "4.	Contaminated land: Land pollution which may require action to reduce risk to people or the environment. As an example, contamination can be assessed through a Phase I or II Environmental Site Assessment.",
        handle:"contaminated_land"
      },
      {
        title: "Energy efficiency",
        tooltip:
        "5.	Energy efficiency: Refers to products or systems using less energy to provide the same consumer benefit.",
        handle:"energy_efficiency"
      },
      {
        title: "Energy supply",
        tooltip:
        "6.	Energy supply: Availability of conventional power (generated by the combustion of fuels: coal, natural gas, oil) or renewable energy (e.g. sun, wind, water, organic plant and waste material).",
        handle:"energy_supply"
      },
      {
        title: "Flooding",
        tooltip:
        "7.	Flooding: Refers to a rising and overflowing of a body of water especially onto normally dry land often caused by heavy rain, flash flooding, or sea level rise.",
        handle:"flooding"
      },
      {
        title: "Greenhouse gas emissions",
        tooltip:
        "8.	Greenhouse gas emissions: GHGs refers to the seven gases listed in the GHG Protocol Corporate Standard: carbon dioxide (CO2); methane (CH4); nitrous oxide (N2O); hydrofluorocarbons (HFCs); perfluorocarbons (PFCs); nitrogen trifluoride (NF3) and sulphur hexafluoride (SF6). They are expressed in CO2 equivalents (CO2e).",
        handle:"green_house_emission"
      },
      {
        title: "Health and well-being",
        tooltip:
        "9.	Health and well-being: “Health is a complete state of physical, mental and social well-being, not merely the absence of disease or infirmity”(WHO). Health & well-being is impacted by genetics and individual behaviour as well as environmental conditions. Particularly relevant to GRESB stakeholders are the social determinants of health, which are the “conditions in which people are born, grow, work, live and age, and the wider set of forces and systems shaping the conditions of daily life.” These are the conditions that enable or discourage healthy living. This could include issues such as physical activity, healthy eating, equitable workplaces, maternity and paternity leave, access to healthcare, reduction in toxic exposures, etc.",
        handle:"health_well_being"
      },
      {
        title: "Indoor environmental quality",
        tooltip:
        "10.	Indoor environmental quality: Refers to the conditions inside the building. It includes air quality, access to daylight and views, pleasant acoustic conditions and occupant control over lighting and thermal comfort.",
        handle:"indoor_environment"
      },
      {
        title: "Natural hazards",
        tooltip:
        "11.	Natural hazards: Naturally occurring hazards, including but not limited to flooding, drought, hail storms, earthquakes and fire (including wildfire).",
        handle:"natural_hazard"
      },
      {
        title: "Regulatory risks",
        tooltip:
        "12.	Regulatory risks: Examples include, but are not limited to: mandatory energy/carbon disclosure schemes, changes in taxes e.g. carbon tax, extreme volatility in energy prices due to regulation, zoning.",
        handle:"regulatory_risks"
      },
      {
        title: "Resilience to catastrophe/disaster",
        tooltip:
        "13.	Resilience to catastrophe/disaster: Preparedness of the built environment towards existing and future threats of natural disaster (e.g., the ability to absorb disturbances such as increased precipitation or flooding while maintaining its structure). This can be achieved by management policies, informational technologies, educating tenant, community, suppliers and physical measures at the asset level.",
        handle:"resilience_catastrophe"
      },
      {
        title: "Risk assessment",
        tooltip:
        "14.	Risk assessment: Careful examination of the factors that could potentially adversely impact the value or longevity of a real estate asset. The results of the assessment assist in identifying measures that have to be implemented in order to prevent and mitigate the risks.",
        handle:"risk_assesment"
      },
      {
        title: "Socio-economic risks",
        tooltip:
        "15.	Socio-economic risks: Impact on social well-being, livelihoods and prosperity of local communities and individuals. Examples include: economic/political instability, social housing, vulnerability to pandemics and epidemics, crime and vandalism, and the displacement of people.",
        handle:"socio_economic_risk"
      },
      {
        title: "Transportation risks",
        tooltip:
        "16.	Transportation risks: Risks associated with transportation around the location of a building in relation to pedestrian, bicycle and mass-transit networks, in context of the existing infrastructure and amenities in the surrounding area.",
        handle:"trasportation_risk"
      },
      {
        title: "Waste management",
        tooltip:
        "17.	Waste management: Issues associated with hazardous and non-hazardous waste generation, reuse, recycling, composting, recovery, incineration, landfill and on-site storage.",
        handle:"water_management"
      },
      {
        title: "Water efficiency",
        tooltip:
        "18.	Water efficiency: Refers to the conservative use of water resources through water-saving technologies to reduce Consumption.",
        handle:"water_efficiency"
      },
      {
        title: "Water supply",
        tooltip:
        "19.	Water supply: Provision of surface water, groundwater, rainwater collected directly or stored by the entity, waste water from another organization, municipal water supplies or other water utilities, usually via a system of pumps and pipes.",
        handle:"water_supply"
    },
  ],
];
export const energyEfficiencyMeasures = [
  [
    {
      title: "Building automation system upgrades/replacements",
      tooltip:
        "1.	Building automation system upgrades/replacements: Refers to the computer-based centralized system installed in buildings that controls and monitors equipment such as ventilation, airconditioning, heating, lighting, alarms and communications.",
      handle: "automation_system_upgrades"
    },
    {
      title: "Upgrades and replacements",
      tooltip:
      "2.	Upgrades and replacements refers to the process of ensuring the building automation system is operating at full capacity, as to achieve optimal management of systems and increase energy efficiency.",
      handle: "upgrades_replacement"
    },
    {
      title: "Building energy management system",
      tooltip:
      "3.	Building energy management system: Computer‐based automated systems that monitor and control all energy‐related systems, including all mechanical and electrical equipment in buildings.",
      handle: "energy_management_system"
    },
    {
      title:
      "Installation of high-efficient equipment and electrical appliances",
      tooltip:
      "4.	Installation of high-efficient equipment and electrical appliances: Specification and purchase of electrical equipment and appliances that minimize the building‘s energy needs. This includes, but it is not limited to: energy efficient lighting upgrades/replacements and HVAC system upgrades/replacements.",
      handle: "electrical_appliances_installation"
    },
    {
      title: "Installation of on-site renewable energy",
      tooltip:
      "5.	Installation of on-site renewable energy: Renewable energy produced on-site, to meet some or all of the building’s energy requirements.",
      handle: "renewable_energy_installation"
    },
    {
      title: "Occupier engagement/informational technologies",
      tooltip:
      "7.	Occupier engagement/informational technologies: Communication and information technologies implemented to inform and engage with tenants in regards to their energy use.",
      handle: "information_technology"
    },
    {
      title: "Smart grid/smart building technologies",
      tooltip:
      "8.	Smart grid/smart building technologies: Computer-based control and automation of electricity network systems, to support and manage electricity demand in a sustainable, integrated manner.",
      handle: "smart_grid_technologies"
    },
    {
      title: "System commissioning",
      tooltip:
      "9.	System commissioning: The process of ensuring that systems are designed, installed, and functionally tested, and that they are capable of being operated and maintained to perform optimally.",
      handle: "system_commissionig"
    },
  ],
];

export const waterEfficiencyMeasures = [
  [
    {
      title: "Cooling Tower Water Management",
      tooltip:
      "1.	Cooling tower water management: A cooling tower is a heat rejection device which extracts waste heat to the atmosphere through the cooling of a water stream to a lower temperature. Reduction of potable water Consumption for cooling towers (or evaporative condenser equipment) can be achieved through effective water management, including conducting a water analysis to measure the concentration of at least five control parameters in order to optimize the cooling tower cycles and/or use of non-potable makeup water for a minimum of 20% of the makeup water.",
      handle: "cooling_water_management"
    },
    {
      title: "Drip / Smart Irrigation",
      tooltip:
      "2.	Drip/smart irrigation: Drip irrigation systems save water by irrigating, fertilizing and aerating trees, shrubs, plants and bushes directly at the roots. Smart irrigation systems save water by adjusting the watering schedule and amount of water used for irrigation based on a variety of factors and inputs, including weather, plant species and soil type.",
      handle: "drip_irrigation"
    },
    {
      title: "Drought Tolerant / Native Landscape",
      tooltip:
      "3.	Drought tolerant/native landscaping: Adapted or indigenous vegetation that has evolved to the geography, hydrology and climate of a region requiring minimal or no supplemental watering beyond natural rainfall.",
      handle: "drought_tolerant"
    },
    {
      title: "Dry Fixtures",
      tooltip:
      "4.	Dry fixtures: Fixtures that do not require the use of water, such as composting toilet systems and waterless urinals.",
      handle: "dry_friction"
    },
    {
      title: "Grey Water",
      tooltip:
      "5.	Grey water: Wastewater generated from hand basins, showers and other water-using devices and equipment.",
      handle: "grey_water"
    },
    {
      title: "High Efficiency Fixtures",
      tooltip:
      "6.	High-efficiency fixtures: Appliances and plumbing equipment that conserve water without compromising performance (also known as “ultra-low-flow” fixtures).",
      handle: "high_efficiency_fixture"
    },
    {
      title: "Leak Detection System",
      tooltip:
      "7.	Leak detection system: Systems that detect water leaks. Examples can include, but are not limited to: condensate water overflow, chiller water leaks, plumbing line cracks, heating/cooling piping leaks and outside seepage.",
      handle: "leak_detection_system"
    },
    {
      title: "Measure",
      tooltip:
      "8.	Measure: The actual project or activity undertaken/implemented to improve energy efficiency as part of the selected category.",
      handle: "measure"
    },
    {
      title: "Metering of water subsystems",
      tooltip:
      "9.	Metering of water subsystems: Installing sub-meters to measure the water Consumption of applicable subsystems, such as irrigation, indoor plumbing fixtures, domestic hot water, reclaimed water or other process water uses, which supports effective water management and identifying opportunities for additional water savings.",
      handle: "metering_subsystem"
    },
    {
      title: "On-site wastewater treatment",
      tooltip:
      "10.	On-site wastewater treatment: Process of water decontamination as a consequence of any anthropogenic, industrial or commercial use, before the water is released again into the environment or is re-used.",
      handle: "waste_water_treatment"
    },
    {
      title: "Stormwater",
      tooltip:
      "11.	Stormwater: Water that collects during precipitation, which can be stored on-site for eventual reuse for non-potable applications. Examples of applications for reuse can include, but are not limited to: landscape irrigation and/or flush fixtures.",
      handle: "storewater"
    },
  ],
];

export const wasteManagementMeasures = [
  [
    {
      title: "Composting landscape and/or food waste",
      tooltip:
      "1.	Composting landscape and/or food waste: Composting is the controlled decomposition of organic material which produces useful soil amendment products. Engage in landscape and/or food waste composting either on-site or by contracting with a composting service provider.",
      colSpan: 1,
      rowSpan: 1,
      handle: "composting_foodwaste"
    },
    {
      title: "Measure",
      tooltip:
      "2.	Measure: The actual project or activity undertaken/implemented to improve energy efficiency as part of the selected category.",
      colSpan: 1,
      rowSpan: 1,
      handle: "measure"
    },
    {
      title: "Ongoing waste performance monitoring",
      tooltip:
      "3.	Ongoing waste performance monitoring: Track and measure ongoing waste volumes generated on a minimum quarterly basis, by either weight or volume, to help identify diversion and recycling opportunities within the entity. Conduct a minimum annual review to evaluate performance.",
      colSpan: 1,
      rowSpan: 1,
      handle: "waste_performance_monitoring"
    },
    {
      title: "Recycling program",
      tooltip:
      "4.	Recycling program: A program for materials that can be locally recycled and contracted with a recycling service provider. Provide appropriately sized recycling collection and storage areas within the entity’s real estate holdings to enable occupants to sort, collect and divert materials from landfill.",
      colSpan: 1,
      rowSpan: 1,
      handle: "recycling"
    },
    {
      title: "Solid waste management",
      tooltip:
      "5.	Solid waste management: Hazardous and non-hazardous waste including reuse, recycling, composting, recovery, incineration, landfill, and on-site storage.",
      colSpan: 1,
      rowSpan: 1,
      handle: "solid_waste"
    },
    {
      title: "Waste stream audit",
      tooltip:
      "6.	Waste stream audit: A waste audit is a process for evaluating the materials in your building waste stream, and assessing whether materials are being disposed of correctly. Teams collect the waste during a designated collection period and then physically sort through it and quantify the amount of each material type. The waste audit process allows building managers to identify opportunities for diverting waste streams away from the landfill and toward recycling or composting.",
      colSpan: 1,
      rowSpan: 1,
      handle: "waste_audit"
    },
  ],
];
export const esgTargets = [
  {
    title: "Area",
    tooltip: "",
    colSpan: 1,
    rowSpan: 1,
  },
  {
    title: "Target type",
    tooltip:
      "Target type: Select from the dropdown menu whether the performance indicator target is based on absolute or like-for-like data, or is intensity-based.",
    colSpan: 1,
    rowSpan: 1,
  },
  {
    title: "Long-Term Target (%)",
    tooltip: "",
    colSpan: 1,
    rowSpan: 1,
  },
  {
    title: "Baseline year",
    tooltip:
      "Baseline year: Include a baseline year. Participants have the option to select a baseline year from 2000 onwards.",
    colSpan: 1,
    rowSpan: 1,
  },
  {
    title: "End year",
    tooltip:
      "End year: This is the end date for the long-term reduction targets. The end year must be 2022 or later, and must be at least 3 years later than the baseline year.",
    colSpan: 1,
    rowSpan: 1,
  },
  {
    title: "Are These Targets Communicated Externally",
    tooltip:
      "Externally communicated: Select yes or no for each performance indicator target.",
    colSpan: 1,
    rowSpan: 1,
  },
];

export const buildingCertification = [
  [
    { title: "Property Type", tooltip: "", colSpan: 1, rowSpan: 2, handle: "property_type" },
    { title: "Certification 1", tooltip: "", colSpan: 3, rowSpan: 1 },
    { title: "Certification 2", tooltip: "", colSpan: 3, rowSpan: 1 },
    { title: "Certification 3", tooltip: "", colSpan: 3, rowSpan: 1 },
    { title: "Energy Exchange", tooltip: "", colSpan: 3, rowSpan: 1 },
  ],
  [
    {
      title: "Scheme *1",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "certification_1_scheme",
    },
    {
      title: "Floor Area Covered (m2/sq.Ft)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "certification_1_floor_area",
    },
    {
      title: "Country",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "certification_1_country",
    },

    {
      title: "Scheme *1",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "certification_2_scheme",
    },
    {
      title: "Floor Area Covered (m2/sq.Ft)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "certification_2_floor_area",
    },
    {
      title: "Country",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "certification_2_country",
    },
    {
      title: "Scheme *1",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "certification_3_scheme",
    },
    {
      title: "Floor Area Covered (m2/sq.Ft)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "certification_3_floor_area",
    },
    {
      title: "Country",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "certification_3_country",
    },
    {
      title: "Scheme *1",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "energy_exchange_scheme",
    },
    {
      title: "Floor Area Covered (m2/sq.Ft)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "energy_exchange_floor_area",
    },
    {
      title: "Country",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "energy_exchange_country",
    },
  ],
];

export const efficiencyMeasures = [
  [
    {
      title: "Building Automation System Upgrades / Replacements",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "automation",
    },
    {
      title: "Upgrades & Replacements",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "upgrades",
    },
    {
      title: "Building Energy Management System",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "energy_management",
    },
    {
      title: "Installation Of High Efficient & Electrical Appliances",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "high_efficiency",
    },
    {
      title: "Installation Of On-Site Renewable Energy",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "on_site",
    },
    {
      title: "Measure",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "measure",
    },
    {
      title: "Occupier Engagement / Information Technologies",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "occupier",
    },
    {
      title: "Smart Grid / Smart Building Technologies",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "smart_grid",
    },
    {
      title: "System Commissioning",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "commision",
    },
  ],
];

export const energy = [
  [
    // {
    //   title: "Asset characteristics",
    //   tooltip: "",
    //   colSpan: 2,
    //   rowSpan: 2,
    // },
    // {
    //   title: "reporting characteristics",
    //   tooltip: "",
    //   colSpan: 1,
    //   rowSpan: 2,
    // },
    {
      title: "Reporting Level",
      tooltip: "",
      colSpan: 6,
      rowSpan: 2,
    },
    {
      title: "Data Availability",
      tooltip: "",
      colSpan: 2,
      rowSpan: 2,
    },
    {
      title: "Whole Building",
      tooltip: "",
      colSpan: 9,
      rowSpan: 2,
    },
    {
      title: "Base Building",
      tooltip: "",
      colSpan: 18,
      rowSpan: 1,
    },
    {
      title: "Tenant Spaces",
      tooltip: "",
      colSpan: 18,
      rowSpan: 1,
    },
    {
      title: "Outdoor / Exterior Areas / Parking",
      tooltip: "",
      colSpan: 4,
      rowSpan: 1,
    },
    {
      title: "Renewable Energy",
      tooltip: "",
      colSpan: 5,
      rowSpan: 2,
    },
  ],
  [
    {
      title: "Shared Services",
      tooltip: "",
      colSpan: 9,
      rowSpan: 1,
    },
    {
      title: "Common Areas",
      tooltip: "",
      colSpan: 9,
      rowSpan: 1,
    },
    {
      title: "Tenant Spaces - Landlord Controlled",
      tooltip: "",
      colSpan: 9,
      rowSpan: 1,
    },
    {
      title: "Tenant Spaces - Tenant Controlled",
      tooltip: "",
      colSpan: 9,
      rowSpan: 1,
    },
    {
      title: "Outdoor / Exterior Areas / Parking - Landlord Controlled",
      tooltip: "",
      colSpan: 2,
      rowSpan: 1,
    },
    {
      title: "Outdoor / Exterior Areas / Parking - Landlord Controlled",
      tooltip: "",
      colSpan: 2,
      rowSpan: 1,
    },
  ],

  [
    {
      title: "Whole Building",
      tooltip: "",
      colSpan: 1,
      rowSpan: 2,
      handle: "whole_building",
    },
    {
      title: "Base Building",
      tooltip: "",
      colSpan: 2,
      rowSpan: 1,
    },
    {
      title: "Reporting Level",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "From (In Reporting Year)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "To (In Reporting Year)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "Fuels",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "District Heating And Cooling",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Electricity",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Fuels",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "District Heating And Cooling",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Electricity",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Fuels",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "District Heating And Cooling",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Electricity",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Fuels",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "District Heating And Cooling",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Electricity",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Fuels",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "District Heating And Cooling",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Electricity",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Fuels",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "Electricity",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "Fuels",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "Electricity",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "Generated And Consumed On-Site By Landlord",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "Generated On-Site And Exported By Landlord",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "Generated And Consumed On-Site By Third Party Or Tenant",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "Generated Off-Site And Purchased By Landlord",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "Generated Off-Site And Purchased By Tenant",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
  ],
  [
    {
      title: "Common Areas M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_building_common",
    },
    {
      title: "Shared Services M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_building_shared",
    },
    {
      title: "Total M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "reporting_total",
    },
    {
      title: "Landlord Controlled M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "reporting_landlord_controlled",
    },
    {
      title: "Tenant Controlled M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "reporting_tenant_controlled",
    },
    {
      title: "Mandatory",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "data_from",
    },
    {
      title: "Mandatory",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "data_to",
    },
    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "whole_fuel_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "whole_fuel_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "whole_fuel_max_floor_area",
    },

    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "whole_hnc_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "whole_hnc_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "whole_hnc_max_floor_area",
    },
    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "whole_electricity_consumption",
    },

    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "whole_electricity_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "whole_electricity_max_floor_area",
    },

    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_shared_fuel_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_shared_fuel_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_shared_fuel_max_floor_area",
    },
    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_shared_hnc_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_shared_hnc_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_shared_hnc_max_floor_area",
    },

    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_shared_electricity_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_shared_electricity_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_shared_electricity_max_floor_area",
    },

    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_common_fuel_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_common_fuel_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_common_fuel_max_floor_area",
    },

    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_common_hnc_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_common_hnc_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_common_hnc_max_floor_area",
    },

    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_common_electricity_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_common_electricity_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_common_electricity_max_floor_area",
    },
    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "landlord_controlled_fuel_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "landlord_controlled_fuel_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "landlord_controlled_fuel_max_floor_area",
    },

    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "landlord_controlled_hnc_consumption",
    },

    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "landlord_controlled_hnc_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "landlord_controlled_hnc_max_floor_area",
    },

    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "landlord_controlled_electricity_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "landlord_controlled_electricity_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "landlord_controlled_electricity_max_floor_area",
    },
    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "tenant_controlled_fuel_consumption",
    },

    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "tenant_controlled_fuel_floor_area",
    },

    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "tenant_controlled_fuel_max_floor_area",
    },

    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "tenant_controlled_hnc_consumption",
    },

    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "tenant_controlled_hnc_floor_area",
    },

    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "tenant_controlled_hnc_max_floor_area",
    },

    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "tenant_controlled_electricity_consumption",
    },

    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "tenant_controlled_electricity_floor_area",
    },

    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "tenant_controlled_electricity_max_fuel_area",
    },

    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "outdoor_landlord_controlled_fuel",
    },

    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "outdoor_landlord_controlled_electricity",
    },

    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "outdoor_tenant_controlled_fuel",
    },

    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "outdoor_tenant_controlled_electricity",
    },

    {
      title: "(Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "energy_onsite_consumed_landlord",
    },

    {
      title: "(Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "energy_onsite_exported_landlord",
    },

    {
      title: "(Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "energy_onsite_consumed_tenant",
    },

    {
      title: "(Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "energy_offsite_landlord",
    },

    {
      title: "(Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "energy_offsite_tenant",
    },
  ],
];

export const water = [
  [
    {
      title: "Data Availability",
      tooltip: "",
      colSpan: 2,
      rowSpan: 1,
    },
    {
      title: "Whole Building",
      tooltip: "",
      colSpan: 3,
      rowSpan: 2,
    },
    {
      title: "Base Building",
      tooltip: "",
      colSpan: 6,
      rowSpan: 1,
    },
    {
      title: "Tenant Spaces",
      tooltip: "",
      colSpan: 6,
      rowSpan: 1,
    },
    {
      title: "Outdoor / Exterior Areas / Parking",
      tooltip: "",
      colSpan: 2,
      rowSpan: 1,
    },
    {
      title: "Reused And Recycled",
      tooltip: "",
      colSpan: 4,
      rowSpan: 1,
    },
  ],
  [
    {
      title: "From (In Reporting Year)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "To (In Reporting Year)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },

    {
      title: "Shared Services",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Common Areas",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Tenant Spaces - Landlord Controlled",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Tenant Spaces - Tenant Controlled",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Outdoor / Exterior Areas / Parking - Landlord Controlled",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "Outdoor / Exterior Areas / Parking - Tenant Controlled",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "On - Site",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Off - Site",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
  ],
  [
    {
      title: "Mandatory",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "data_from",
    },
    {
      title: "Mandatory",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "data_to",
    },
    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "whole_building_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "whole_building_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "whole_building_max_floor_area",
    },
    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_building_shared_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_building_shared_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_building_shared_max_floor_area",
    },
    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_building_common_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_building_common_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "base_building_common_max_floor_area",
    },
    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "landlord_controlled_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "landlord_controlled_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "landlord_controlled_max_floor_area",
    },
    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "tenant_controlled_consumption",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "tenant_controlled_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "tenant_controlled_max_floor_area",
    },
    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "outdoor_landlord_controlled_consumption",
    },
    {
      title: "Consumption (Kwh)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "outdoor_tenant_controlled_consumption",
    },
    {
      title: "Water Reuse (M3)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "on_site_water_reuse",
    },
    {
      title: "Water Capture (M3)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "on_site_water_capture",
    },
    {
      title: "Water Extraction (M3)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "on_site_water_extraction",
    },
    {
      title: "Water Purchased (M3)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "off_site_water_purchased",
    },
  ],
];
export const ghg = [
  [
    {
      title: "Scope 1",
      tooltip: "",
      colSpan: 4,
      rowSpan: 1,
    },
    {
      title: "Scope 2 : Location Based",
      tooltip: "",
      colSpan: 4,
      rowSpan: 1,
    },
    {
      title: "Scope 2 : Market Based (Optional)",
      tooltip: "",
      colSpan: 2,
      rowSpan: 1,
    },
    {
      title: "Scope 3 : Tenant Emissions",
      tooltip: "",
      colSpan: 4,
      rowSpan: 1,
    },
    {
      title: "GHG Offsets Purchased",
      tooltip: "",
      colSpan: 1,
      rowSpan: 2,
    },
  ],
  [
    {
      title: "Whole Building",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Outdoor Exterior Areas/ Parking",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "Whole Building",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Outdoor Exterior Areas/ Parking",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "Whole Building",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "Outdoor Exterior Areas/ Parking",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
    {
      title: "Whole Building",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
    },
    {
      title: "Outdoor Exterior Areas/ Parking",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
    },
  ],
  [
    {
      title: "Emissions (Tonne)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "scope_1_building_emission",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "scope_1_building_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "scope_1_building_max_floor_area",
    },
    {
      title: "Emissions (Tonne)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "scope_1_outdoor_emission",
    },
    {
      title: "Emissions (Tonne)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "scope_2_location_building_emission",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "scope_2_location_building_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "scope_2_location_building_max_floor_area",
    },
    {
      title: "Emissions (Tonne)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "scope_2_location_outdoor_emission",
    },
    {
      title: "Emissions (Tonne)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "scope_2_market_building_emission",
    },
    {
      title: "Emissions (Tonne)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "scope_2_market_outdoor_emission",
    },
    {
      title: "Emissions (Tonne)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "scope_3_building_emission",
    },
    {
      title: "Floor Area Covered M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "scope_3_building_floor_area",
    },
    {
      title: "Maximum Floor Area M2/Sq. Ft",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "scope_3_building_max_floor_area",
    },
    {
      title: "Emissions (Tonne)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "scope_3_outdoor_emission",
    },
    {
      title: "Emissions (Tonne)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "offset_emission"
    },
  ],
];

export const waste = [
  [
    {
      title: "Data Availability",
      tooltip: "",
      colSpan: 2,
      rowSpan: 1,
      handle: "", // Add the appropriate key here
    },
    {
      title: "Whole Building",
      tooltip: "",
      colSpan: 3,
      rowSpan: 1,
      handle: "", // Add the appropriate key here
    },
    {
      title: "Proportion Of Waste By Disposal Route",
      tooltip: "",
      colSpan: 6,
      rowSpan: 1,
      handle: "", // Add the appropriate key here
    },
  ],
  [
    {
      title: "From (In Reporting Year)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "", // Add the appropriate key here
    },
    {
      title: "To (In Reporting Year)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "", // Add the appropriate key here
    },
    {
      title: "Hazardous Waste",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "", // Add the appropriate key here
    },
    {
      title: "Non-Hazardous Waste",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "", // Add the appropriate key here
    },
    {
      title: "Data Coverage",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "", // Add the appropriate key here
    },
    {
      title: "Landfill",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "", // Add the appropriate key here
    },
    {
      title: "Incineration",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "", // Add the appropriate key here
    },
    {
      title: "Reuse",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "", // Add the appropriate key here
    },
    {
      title: "Waste To Energy",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "", // Add the appropriate key here
    },
    {
      title: "Recycling",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "", // Add the appropriate key here
    },
    {
      title: "Other / Unknown",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "", // Add the appropriate key here
    },
  ],
  [
    {
      title: "Mandatory",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "data_from", // Add the appropriate key here
    },
    {
      title: "Mandatory",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "data_to", // Add the appropriate key here
    },
    {
      title: "Weight (Tonne)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "hazardous_waste", // Add the appropriate key here
    },
    {
      title: "Weight (Tonne)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "non_hazardous_waste", // Add the appropriate key here
    },
    {
      title: "Mandatory (%)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "data_coverage", // Add the appropriate key here
    },
    {
      title: "(%)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "landfill", // Add the appropriate key here
    },
    {
      title: "(%)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "incineration", // Add the appropriate key here
    },
    {
      title: "(%)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "reuse", // Add the appropriate key here
    },
    {
      title: "(%)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "waste_to_energy", // Add the appropriate key here
    },
    {
      title: "(%)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "recycling", // Add the appropriate key here
    },
    {
      title: "(%)",
      tooltip: "",
      colSpan: 1,
      rowSpan: 1,
      handle: "others", // Add the appropriate key here
    },
  ],
];
