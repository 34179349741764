import React, { useState } from 'react'
import { Modal, Typography, Box, Button, TextField } from '@mui/material'
const AddDocsModal = ({ open, handleClose, handleDocSubmit }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 400,
        bgcolor: 'background.paper',
        // border: '1px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const [formData, setFormData] = useState({
        doc_name: "",
        file: null
    })
    const [error, setError] = useState({
        doc_name: "",
        file: ""
    })
    // Validation function
    const validateForm = () => {
        let isValid = true;
        let tempErrors = { doc_name: '', file: '' };

        // Check if doc_name is provided
        if (!formData.doc_name) {
            tempErrors.doc_name = 'Document name is required';
            isValid = false;
        }

        // Check if file is provided
        if (!formData.file) {
            tempErrors.file = 'Document file is required';
            isValid = false;
        }

        // Update the errors state
        setError(tempErrors);

        return isValid;
    };
    const submissionHandler = async (e) => {
        e.preventDefault()
        if (!validateForm()) {
            return
        }
        const formDataToSend = new FormData()
        formDataToSend.append('doc_name', formData.doc_name)
        formDataToSend.append('file', formData.file)
        await handleDocSubmit(formDataToSend)
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Add your documents

                </Typography>
                <form encType='multipart/form-data' onSubmit={submissionHandler} >
                    <TextField
                        label="Document Name"
                        fullWidth
                        value={formData.doc_name}
                        onChange={(e) => setFormData((prevState) => ({ ...prevState, doc_name: e.target.value }))}
                        margin="normal"
                        error={!!error.doc_name}
                        helperText={error.doc_name}
                    />
                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Upload Document
                        <input
                            type="file"
                            accept=".pdf,.doc,.docx,.jpg,.png"
                            onChange={(e) => setFormData((prevState) => ({ ...prevState, file: e.target.files[0] }))}
                            hidden

                        />
                    </Button>
                    {error.file && (
                        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                            {error.file}
                        </Typography>
                    )}
                    {formData.file && (
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            Selected File: {formData.file.name}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{ mt: 3 }}
                    >
                        Submit
                    </Button>
                </form>

            </Box>
        </Modal>
    )
}

export default AddDocsModal