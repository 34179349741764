import React, { useState, useEffect } from 'react'
import ScrollableTable from './ScrollableTable'
import { water } from '../../constant/assetManagement'
import useGroupedAssets from '../../hooks/useGroupedAssets'
import { addWaterAsset, getWaterAsset, deleteWaterAsset,updateWaterAsset } from '../../services/private/assetManagement/assetServices'
// import { successToast } from '../../utils/alerts'
const WaterDataTable = ({addField, setAddField, searchQuery}) => {
  const [trigger, setTrigger] = useState(false)
  const data = useGroupedAssets(getWaterAsset, trigger)
  const [assetData, setAssetData ]= useState(data)
  const handleAddAsset = async (formData) => {
    const res = await addWaterAsset(formData)
    if (res.type === "RXSUCCESS") {
      setTrigger((prev) => prev + 1)
    }
    return res
  }
  const handleDeleteAsset = async (id)=>{
    const res = await deleteWaterAsset(id)
    if(res.type==="RXSUCCESS"){
      setTrigger((prev) => prev + 1); // Toggle refreshTrigger to re-fetch data
    }
    return res
  }
  const handleUpdateAsset = async (payload, id) => {
    const res = await updateWaterAsset(payload, id)
    if (res.type === "RXSUCCESS") {
      setTrigger((prev) => prev + 1)
    }
    return res
  }
  useEffect(()=>{
    if(searchQuery){
      const searchTerm = searchQuery.toLowerCase()
      const filteredData = data.filter((item)=>
        item.asset_name.toLowerCase().includes(searchTerm)
      )
      setAssetData(filteredData)
    }else {
      setAssetData(data)
    }
  },[searchQuery, data])
  return (
    <ScrollableTable
      tableContent={water}
      colLength={24}
      headCellPadding={30.7}
      actionCellPadding={74.7}
      maxWidth={650}
      cellBorder='1px solid #e8ebf2'
      fixedLeftHeaderTable={true}
      assetData={assetData}
      addField={addField}
      setAddField={setAddField}
      postRequest={handleAddAsset}
      deleteRequest = {handleDeleteAsset}
      putRequest={handleUpdateAsset}
    />
  )
}

export default WaterDataTable