/** @format */

import React, {  useState } from 'react';
import {
  Typography,
  MenuItem,
  Menu,
  Button,
  AppBar,
  Toolbar,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import styles from '../../pages/dataVault/dataVault.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SETTAB } from '../../store/assetManagement/assetSlice';

const AssetNavBar = () => {
  const [anchorEl1, setAnchorEl1] = useState(null);
  const { activeTab } = useSelector((state) => state.asset);

  const dispatch = useDispatch();
  const handleMenuOpen1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleMenuClose1 = () => {
    setAnchorEl1(null);
  };

  const handleMenuLeave1 = () => {
    setTimeout(() => {
      if (!anchorEl1) {
        handleMenuClose1();
      }
    }, 300); // Adjust the delay as needed to prevent flickering
  };

  const location = useLocation();
  const navigate = useNavigate();
  const handletab = (tab) => {
    setAnchorEl1(null);
    if (
      location.pathname.includes('view-assets') ||
      location.pathname.includes('add-new-asset')
    ) {
      navigate('/asset-management');
    }
    dispatch(SETTAB(tab));
  };
  // useEffect(() => {
  //   if (activeTab === 0 && !location.pathname.includes('add-new-asset')) {
  //     navigate('/asset-management');
  //   }
  //   // eslint-disable-next-line
  // }, [activeTab]);
  return (
    <AppBar
      sx={{
        position: 'relative',
        backgroundColor: '#F6F8FC',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 10,
        paddingBottom: '16px',
      }}
      elevation={0}
    >
      <Toolbar onMouseLeave={handleMenuLeave1} className={styles.testing}>
        <Button
          id="Governance-button"
          aria-controls="nav1-menu"
          aria-haspopup="true"
          onMouseOver={handleMenuOpen1}
          // onMouseLeave={handleMenuClose1}
          endIcon={
            <ExpandMore
              color={activeTab === 0 || activeTab === 4 ? 'primary' : 'esggray'}
            />
          }
          sx={{
            padding: '9px 16px',
            cursor: 'pointer',
            borderBottom:
              activeTab === 0 || activeTab === 4 ? '2px solid #33AE5E' : 'none',
            borderRadius: 0,
          }}
          onClick={() => handletab(0)}
        >
          <Typography
            className={styles.navLinks}
            sx={{
              color: activeTab === 0 || activeTab === 4 ? '33ae5e' : '#82959c',
            }}
          >
            Asset Reporting
          </Typography>
        </Button>
        <Menu
          id="Governance-menu"
          anchorEl={anchorEl1}
          open={Boolean(anchorEl1)}
          onClose={handleMenuClose1}
          elevation={1}
          onMouseLeave={handleMenuLeave1}
        >
          <MenuItem
            onClick={() => handletab(0)}
            sx={{
              color: '#0f333d',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '157%',
              letterSpacing: '0.1px',
            }}
          >
            Our Portfolio
          </MenuItem>
          <MenuItem
            onClick={() => handletab(4)}
            sx={{
              color: '#0f333d',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '157%',
              letterSpacing: '0.1px',
            }}
          >
            Asset Management Plan
          </MenuItem>
        </Menu>
      </Toolbar>
      {/*<Toolbar> */}
      <Button
        id="basic-button"
        aria-controls="nav2-menu"
        aria-haspopup="true"
        sx={{
          padding: '9px 16px',
          cursor: 'pointer',
          borderBottom: activeTab === 1 ? '2px solid #33AE5E' : 'none',
          borderRadius: 0,
          margin: '0 16px 0 0',
        }}
        onClick={() => handletab(1)}
      >
        <Typography
          className={styles.navLinks}
          sx={{ color: activeTab === 1 ? '#33ae5e' : '#82959c' }}
        >
          Sustainability reporting
        </Typography>
      </Button>
      {/* </Toolbar>
  <Toolbar> */}
      <Button
        id="basic-button"
        aria-controls="nav2-menu"
        aria-haspopup="true"
        sx={{
          padding: '9px 16px',
          cursor: 'pointer',
          borderBottom: activeTab === 2 ? '2px solid #33AE5E' : 'none',
          borderRadius: 0,
          // margin: "0 24px",
        }}
        onClick={() => handletab(2)}
      >
        <Typography
          className={styles.navLinks}
          sx={{ color: activeTab === 2 ? '#33ae5e' : '#82959c' }}
        >
          ESG Targets
        </Typography>
      </Button>
      {/* </Toolbar>
  <Toolbar> */}
      <Button
        id="basic-button"
        aria-controls="nav2-menu"
        aria-haspopup="true"
        sx={{
          padding: '9px 16px',
          cursor: 'pointer',
          borderBottom: activeTab === 3 ? '2px solid #33AE5E' : 'none',
          borderRadius: 0,
          margin: '0 16px',
        }}
        onClick={() => handletab(3)}
      >
        <Typography
          className={styles.navLinks}
          sx={{ color: activeTab === 3 ? '#33ae5e' : '#82959c' }}
        >
          Tenants
        </Typography>
      </Button>
      {/* </Toolbar> */}
    </AppBar>
  );
};

export default AssetNavBar;
