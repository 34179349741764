/** @format */

import React, { useState } from 'react';
import {
  Box,
  Button,
  // Checkbox,
  // FormControlLabel,
  // InputLabel,
  Modal,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import SideBar from './SideBar';
import Header from './Header';
import styles from './layout.module.css';
import { useLocation } from 'react-router-dom';
import ShareDataVaultModal from './ShareDataVaultModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const Layout = ({ children }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  return (
    <Box className="app-layout">
      <SideBar />
      <Stack className={styles.layoutRight}>
        <Header />
        <Box
          sx={{
            width: '100%',
            padding: '24px',
            marginTop: '64px',
            bgcolor: '#F6F8FC',
            paddingBottom: 0,
          }}
        >
          {location.pathname.includes('data-vault') && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography className={styles.instruction}>
                Ensure your Data Vault is up to date with all changes.
              </Typography>
              <Button
                variant="contained"
                sx={{ color: '#fff' }}
                onClick={() => setOpen(true)}
              >
                share data vault
              </Button>
            </Stack>
          )}
          <Paper
            sx={{
              bgcolor: '#FFF',
              padding:
                location.pathname === '/' ||
                location.pathname.includes('data-vault') ||
                location.pathname.includes('asset-management') ||
                location.pathname.includes('view-assets')
                  ? '0'
                  : '24px',
            }}
            elevation={0}
          >
            {children}
          </Paper>
        </Box>
      </Stack>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Stack gap={1} sx={style}>
          <ShareDataVaultModal setOpen={setOpen} />
        </Stack>
      </Modal>
    </Box>
  );
};

export default Layout;
