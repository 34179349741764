/** @format */

import { Box, Stack, Tab, Typography } from '@mui/material';
import React, { useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DataVault from '../DataVault';
import styles from './leadershipCommitments.module.css';
import DataVaultQuestionnaire3 from './DataVaultQuestionnaire3';

const LeadershipCommitments = () => {
  const [value, setValue] = useState('1');
  // eslint-disable-next-line
  const [answered, setAnswered] = useState(0);

  const handleInnerTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DataVault>
      <Stack gap="16px" className={styles.leadershipContainer}>
        <Typography variant="h5" className={styles.pageHeading}>
          Leadership Commitments
        </Typography>
        <Typography variant="p" className={styles.explaination}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages,
        </Typography>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <Typography className={styles.questionCount} pb={1}>
            Questions Answered: {answered}/23
          </Typography>
          <TabContext value={value}>
            <TabList onChange={handleInnerTab}>
              <Tab label="1.	ESG Standards" value="1"></Tab>
              <Tab label="2.	Management of Environmental Issues" value="2" />
              <Tab label="3.	Management of Social Issues" value="3" />
              <Tab label="4.	Management of Governance Issues" value="4" />
              <Tab label="5.	Environmental Management System" value="5" />
            </TabList>
            <TabPanel value="1" sx={{ padding: 0 }}>
              <DataVaultQuestionnaire3 tab={1} type={'leadership'} />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: 0 }}>
              <DataVaultQuestionnaire3 tab={2} type={'leadership'} />
            </TabPanel>
            <TabPanel value="3" sx={{ padding: 0 }}>
              <DataVaultQuestionnaire3 tab={3} type={'leadership'} />
            </TabPanel>
            <TabPanel value="4" sx={{ padding: 0 }}>
              <DataVaultQuestionnaire3 tab={4} type={'leadership'} />
            </TabPanel>
            <TabPanel value="5" sx={{ padding: 0 }}>
              <DataVaultQuestionnaire3 tab={5} type={'leadership'} />
            </TabPanel>
          </TabContext>
        </Box>
      </Stack>
    </DataVault>
  );
};

export default LeadershipCommitments;
