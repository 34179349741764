/** @format */

import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import styles from './charts.module.css';
import 'chartjs-plugin-datalabels';

const DoughnutChart = ({
  chartData,
  labelsData,
  type,
  chartHeight,
  containerMaxHeight = 388.5,
  bgColor = '#f6f7fb',
  borderRadius = 6,
}) => {
  const options = {
    // cutout: "50%",
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: 'auto',
        color: '#fff',
        font: {
          weight: 'bold',
        },
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex] + ': ' + value;
        },
      },
    },
  };
  return (
    <Box
      className={styles.doughnutContainer}
      sx={{
        maxHeight: containerMaxHeight,
        bgcolor: bgColor,
        borderRadius: borderRadius + 'px',
      }}
    >
      <Box className={styles.titleContainer}>
        <Typography className={styles.chartTitle} sx={{ height: '67px' }}>
          {type}
        </Typography>
      </Box>
      {chartData.datasets[0].data.length > 1 ? (
        <Stack direction="row" justifyItems="space-around">
          <Box
            className={styles.doughnutChart}
            sx={{ height: chartHeight + 'px' }}
          >
            <Doughnut data={chartData} options={options} />
          </Box>
          <ul className={styles.listItems}>
            {labelsData?.map(
              (item, ind) =>
                item.sum && (
                  <li className={styles.listItem} key={ind}>
                    <div
                      style={{ backgroundColor: item.backgroundColor }}
                      className={`${styles.colorBox} ${
                        styles[`colorBox${ind + 1}`]
                      }`}
                    ></div>
                    <Typography
                      className={styles.legend}
                    >{`${item.location} - ${item.sum}`}</Typography>
                  </li>
                )
            )}
          </ul>
        </Stack>
      ) : (
        <p className={styles.noData}>No data found to display</p>
      )}
    </Box>
  );
};

export default DoughnutChart;
