/** @format */

// import { Card } from "@mui/material";
import React, { useState, useEffect } from "react";
// import theme from "../components/theme";
import FooterCard from "../../components/UI/FooterCard";
import TabComponent from "../../components/UI/TabComponent";
import { Box, Typography, Stack, Button, Pagination } from "@mui/material";
import PropTypes from "prop-types";
// import EnergyCard from "../components/UI/EnergyItemCard";
import MonthRecordCard from "../../components/UI/MonthRecordCard";
import { useNavigate } from "react-router-dom";
import styles from "./energy.module.css";
import {
  fetchEnergyValues,
  deleteEnergyValues,
} from "../../services/private/energy/EnergyService";
import DeleteModal from "../../modals/DeleteModal";
import EnergyAnalytics from "../../components/energy/EnergyAnalytics";
import { successToast,errorToast } from "../../utils/alerts";
import { useSelector } from "react-redux";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant="p">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const Energy = () => {
  const navigate = useNavigate();
  const Limit = 10;
  const [value, setValue] = React.useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entities, setEntities] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [pagination, setPagination] = useState({
    totalRows: 0,
    currentPage: 1,
  });
  const selectedYear = useSelector((state)=> state.years.selectedYear)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const serverRequest = async (selectedYear) => {
    try {
      const response = await fetchEnergyValues(
        selectedYear.fy_start,
        selectedYear.fy_end,
        Limit,
        currentPage
      );
      setEntities(response.data.rows);
      setPagination(response.data.pagination);
    } catch (error) {
      errorToast(error.message);
    }
  };

  const openDeleteModal = async (id) => {
    setSelectedItem(id);
    setDeleteModal(true);
  };

  const handelDeleteRow = async (id) => {
    try {
      await deleteEnergyValues(id);
      successToast("Delete Successfully!");
      setDeleteModal(false);
      setSelectedItem(null);
      await serverRequest(selectedYear);
    } catch (error) {
      setDeleteModal(false);
      errorToast(error.message);
    }
  };

  const handleUpdateMenu = async (id) => {
    setSelectedItem(id);
    navigate(`/energy/update-energy-details/${id}`);
  };

  useEffect(() => {
    serverRequest(selectedYear);
    
    // eslint-disable-next-line
  }, [currentPage,selectedYear]);
  console.log("entites",  entities);

  return (
    <div>
      <TabComponent
        tab1Name={" SUMMARY"}
        tab1Width={"230px"}
        tab2Name={"ANALYTICS"}
        tab2Width={"348px"}
        value={value}
        handleChange={handleChange}
      />
      <CustomTabPanel value={value} index={0} className="target-list-tab-width">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="p" className="page-headline">
            Energy Consumption Details
          </Typography>
          <Button
            variant="contained"
            sx={{ color: "#fff" }}
            onClick={() => navigate("add-energy-details")}
          >
            Add New Asset
          </Button>
        </Stack>

        {entities.map((entity, index) => (
          <MonthRecordCard
            key={index}
            entity={entity}
            label={` ${entity?.green_power}% • ${entity?.bms} • ${entity?.units}`}
            deleteRow={openDeleteModal}
            handelUpdate={handleUpdateMenu}
          />
        ))}

        <Box sx={{ margin: "24px 0" }} className={styles.energyPagination}>
          <Pagination
            count={Math.ceil(pagination.totalRows / Limit)}
            showFirstButton
            showLastButton
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
        {deleteModal && (
          <DeleteModal
            open={deleteModal}
            handelClose={() => {
              setDeleteModal(false);
            }}
            label={"Are you sure you want to delete this?"}
            description={
              "By clicking delete button will permanently delete record"
            }
            deleteRow={handelDeleteRow}
            id={selectedItem}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <EnergyAnalytics />
      </CustomTabPanel>
      <FooterCard />
    </div>
  );
};

export default Energy;
