import React, { useEffect, useState } from 'react'
import { deleteWasteAsset, getWasteAsset, updateWasteAsset } from '../../services/private/assetManagement/assetServices'
import { waste } from '../../constant/assetManagement'
import ScrollableTable from './ScrollableTable'
import useGroupedAssets from '../../hooks/useGroupedAssets'
import { addWasteAsset } from '../../services/private/assetManagement/assetServices'
// import { successToast } from '../../utils/alerts'
const WasteDataTable = ({ addField, setAddField, searchQuery }) => {
  const [trigger, setTrigger] = useState(0)
  const data = useGroupedAssets(getWasteAsset, trigger)
  const [assetData, setAssetData] = useState(data)

  const handleAddAsset = async (formData) => {
    const response = await addWasteAsset(formData);
    // console.log("response:", response);
    if (response.type === "RXSUCCESS") {
      setTrigger((prev) => prev + 1); // Toggle refreshTrigger to re-fetch data
    }
    return response
  }
  const handleDeleteAsset = async (id) => {
    const res = await deleteWasteAsset(id)
    if (res.type === "RXSUCCESS") {
      setTrigger((prev) => prev + 1); // Toggle refreshTrigger to re-fetch data
    }
    return res
  }
  const handleUpdateAsset = async (payload, id) => {
    const res = await updateWasteAsset(payload, id)
    if (res.type === "RXSUCCESS") {
      setTrigger((prev) => prev + 1)
    }
    return res
  }
  useEffect(()=>{
    // console.log("CHD", searchQuery);
    if(searchQuery){
      const searchTerm = searchQuery.toLowerCase()
      const filteredData = data.filter((item)=>
        item.asset_name.toLowerCase().includes(searchTerm)
      )
      // console.log("search results:", filteredData);
      setAssetData(filteredData)
      // return filteredData
    }else {
      setAssetData(data)
    }
  },[searchQuery, data])
  return (
    <div>

      {/* <h1>Waste Data Table</h1> */}
      <ScrollableTable
        tableContent={waste}
        colLength={11}
        headCellPadding={30.7}
        actionCellPadding={74.7}
        maxWidth={650}
        cellBorder="1px solid #e8ebf2;"
        fixedLeftHeaderTable={true}
        assetData={assetData}
        addField={addField}
        setAddField={setAddField}
        postRequest={handleAddAsset}
        deleteRequest={handleDeleteAsset}
        putRequest={handleUpdateAsset}

      />
    </div>
  )
}

export default WasteDataTable