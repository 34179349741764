/** @format */

import axios from 'axios';
import config from '../../../config/config.json';
import { headers } from '../../helpers/helper';
const PREFIX = 'carbon/waste/';

export const saveWasteConsumption = async (payload) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}addWasteConsumption`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

export const updateWasteConsumption = async (id, payload) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.put(
      `${config.API_URL + PREFIX}updateWasteConsumption/${id}`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

export const saveWasteConsumptionDetail = async (payload) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.post(
      `${config.API_URL + PREFIX}addWasteConsumptionDetail`,
      payload,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

export const requestWasteConsumption = async (
  fy_start,
  fy_end,
  limit,
  page
) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${
        config.API_URL + PREFIX
      }fetchWasteConsumptionList?fy_start=${fy_start}&fy_end=${fy_end}&limit=${limit}&page=${page}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

export const requestWasteConsumptionDetails = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}fetchWasteConsumptionDetail/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

export const deleteWasteConsumption = async (id) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.delete(
      `${config.API_URL + PREFIX}deleteWasteConsumption/${id}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (err) {
    throw new Error(err.response.data.message || 'Oops some error occured!');
  }
};

////////////////////////////// Analytics //////////////////////////////
export const wasteDoughnutChart = async (fy_start, fy_end) => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${
        config.API_URL + PREFIX
      }analytics/doughnutChart?fy_start=${fy_start}&fy_end=${fy_end}`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.message || 'Oops some error occured!');
  }
};
export const wasteBarChart = async () => {
  try {
    const auth = await headers();
    const apiResponse = await axios.get(
      `${config.API_URL + PREFIX}analytics/barChart`,
      {
        headers: auth,
      }
    );
    return apiResponse.data;
  } catch (error) {
    throw new Error(error.message || 'Oops some error occured!');
  }
};
